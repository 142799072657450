import React, { useState } from "react";

const Share = ({ pageOwner, pageType }) => {
	const [recipients, setRecipients] = useState("");
	const [isShared, setIsShared] = useState(false);
	const [isSharing, setIsSharing] = useState(false); // To track when sharing is in progress
	const [showShareOptions, setShowShareOptions] = useState(false);
	var eventsPageUrl = pageOwner.pages.events;
	var registryPageUrl = pageOwner.pages.registry;

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = "https://api.mytassel.com";

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const handleEmailChange = (e) => {
		setRecipients(e.target.value);
	};

	const handleShareViaEmail = async () => {
		setIsSharing(true); // Indicate that sharing is in progress
		const recipientEmails = recipients.split(",").map((email) => email.trim());
		try {
			const response = await fetch(`${SERVER_URL}/share-user-pages`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					recipients: recipientEmails,
					senderFirstName: pageOwner.firstName,
					senderLastName: pageOwner.lastName,
					eventsPageUrl,
					registryPageUrl,
				}),
			});

			if (response.ok) {
				setIsShared(true);
				alert("Invitation sent successfully!");
				setShowShareOptions(false); // Hide the share options
				setRecipients(""); // Reset the recipients input
			} else {
				alert("Failed to send the invitation. Please try again.");
			}
		} catch (error) {
			console.error("Error:", error);
			alert("An error occurred. Please try again.");
		} finally {
			setIsSharing(false); // Sharing process is finished
		}
	};

	const handleCopyLink = () => {
		const urlToCopy = pageType === "Registry" ? registryPageUrl : eventsPageUrl;
		navigator.clipboard.writeText(urlToCopy)
			.then(() => alert("Link copied to clipboard!"))
			.catch((err) => console.error("Could not copy link: ", err));

		setShowShareOptions(false); // Hide the share options
	};

	const toggleShareOptions = () => {
		if (!isSharing) { // Prevent toggling while sharing is in progress
			setShowShareOptions(!showShareOptions);
			setIsShared(false); // Reset share status if user wants to share again
		}
	};

	return (
		<div>
			{!showShareOptions && (
				<button onClick={toggleShareOptions} style={styles.eventsButton}>
					<p style={styles.buttonText}>Share {pageOwner.firstName}'s {pageType} Page</p>
				</button>
			)}
			{showShareOptions && (
				<>
					{isSharing && <p style={styles.successMessage}>Sending invitations...</p>}
					{isShared && <p style={styles.successMessage}>Event shared successfully!</p>}
					<input type="text" placeholder="Enter recipient emails, separated by commas" value={recipients} onChange={handleEmailChange} style={styles.input} />

					<div style={styles.buttonContainer}>
						<button onClick={handleShareViaEmail} style={styles.button} disabled={isSharing}>
							Share via Email
						</button>
						<button onClick={handleCopyLink} style={styles.button}>
							Copy Page Link
						</button>
					</div>
				</>
			)}
		</div>
	);
};

// Styles (including your initial styles for eventsButton and eventsButtonText)
const styles = {
	eventsButton: {
		backgroundColor: "#fefde2",
		width: 284,
		height: 55,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		border: "none",
		cursor: "pointer",
	},
	input: {
		margin: "10px 0",
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ccc",
		width: "100%",
		maxWidth: 500,
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	button: {
		margin: "5px",
		padding: "10px 20px",
		borderRadius: "5px",
		cursor: "pointer",
		backgroundColor: "#4c956c",
		color: "white",
		border: "none",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 20,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	successMessage: {
		color: "#fefde2",
		marginTop: "20px",
	},
};

export default Share;
