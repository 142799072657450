// eCards.js

import { getStorage, getFirestore, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../../firebase";
import React, { useState, useEffect, useRef } from "react";
import { View, Text, TextInput, ScrollView, Pressable, Modal, StyleSheet, Platform, Image } from "react-native"; // Added Image import
import { Ionicons } from '@expo/vector-icons'; // Import Ionicons for arrow and close icons


const eCards = [
	{ cardName: "Card1", front: require("../../assets/eCards/card1F.png"), back: require("../../assets/eCards/card1B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard1F.png?alt=media&token=e89f1e1a-1b5c-47a7-9f1d-ae63191e3825" },
	{ cardName: "Card2", front: require("../../assets/eCards/card2F.png"), back: require("../../assets/eCards/card2B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard2F.png?alt=media&token=90f855c0-5a09-46e6-bcaa-92da67ae5cc9" },
	{ cardName: "Card3", front: require("../../assets/eCards/card3F.png"), back: require("../../assets/eCards/card3B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard3F.png?alt=media&token=5e75508d-5b9e-43d6-a051-763404819650" },
	{ cardName: "Card4", front: require("../../assets/eCards/card4F.png"), back: require("../../assets/eCards/card4B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard4F.png?alt=media&token=87489d7e-8288-407b-946e-e6bf27781f5d" },
	{ cardName: "Card5", front: require("../../assets/eCards/card5F.png"), back: require("../../assets/eCards/card5B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard5F.png?alt=media&token=81e5cba2-b04e-46c6-a93f-30ebdf0e8efc" },
	{ cardName: "Card6", front: require("../../assets/eCards/card6F.png"), back: require("../../assets/eCards/card6B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard6F.png?alt=media&token=2b6ac1fd-7d11-4bc2-966f-94e7dbb2fac7" },
	{ cardName: "Card7", front: require("../../assets/eCards/card7F.png"), back: require("../../assets/eCards/card7B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard7F.png?alt=media&token=676e0289-2a4c-40b4-80aa-9d3391312d9d" },
	{ cardName: "Card8", front: require("../../assets/eCards/card8F.png"), back: require("../../assets/eCards/card8B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard8F.png?alt=media&token=aeb5bd9d-f7a1-4362-91e8-8c5874462bba" },
	{ cardName: "Card9", front: require("../../assets/eCards/card9F.png"), back: require("../../assets/eCards/card9B.png"), frontStorageURL: "https://firebasestorage.googleapis.com/v0/b/my-tassel.appspot.com/o/eCards%2Fcard9F.png?alt=media&token=6b175a87-7a7e-4fd4-ac50-51f2c5f4b807" },
];

const STANDARD_CARD_WIDTH = 300;
const STANDARD_CARD_HEIGHT = 450;

const ECardSelectionModal = ({ isVisible, onSelect, onClose }) => {

	const [selectedCard, setSelectedCard] = useState(null);
	const [isCardSelected, setIsCardSelected] = useState(false); // New state variable
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
	const canvasRef = useRef(null);
	const scrollViewRef = useRef(null); // Create a ref for the ScrollView

	// Function to scroll the ScrollView
	const scroll = (direction) => {
		scrollViewRef.current.scrollTo({
			x: direction === 'right' ? 250 : -250, // Scroll by 250 pixels to the right or left
			animated: true,
		});
	};

	// Function to calculate average brightness of cards
	const getAverageBrightness = (image) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		canvas.width = image.width;
		canvas.height = image.height;
		ctx.drawImage(image, 0, 0);
		const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
		const data = imageData.data;
		let r, g, b, avg;
		let colorSum = 0;

		for (let x = 0, len = data.length; x < len; x += 4) {
			r = data[x];
			g = data[x + 1];
			b = data[x + 2];
			avg = Math.floor((r + g + b) / 3);
			colorSum += avg;
		}

		const brightness = Math.floor(colorSum / (canvas.width * canvas.height));
		return brightness;
	};

	const handleCardSelect = (card) => {
		setSelectedCard(card);
		setIsCardSelected(true); // Set to true when a card is selected
	};

	const clearSelectedCard = () => {
		setSelectedCard(null);
		setIsCardSelected(false); // Reset the state
	};

	useEffect(() => {
		const drawCanvas = async () => {
			if (selectedCard && Platform.OS === "web" && canvasRef.current) {
				const canvas = canvasRef.current;
				const ctx = canvas.getContext("2d");
				const image = document.createElement('img');
				image.src = selectedCard.back;

				await new Promise((resolve) => {
					image.onload = resolve;
				});

				canvas.width = STANDARD_CARD_WIDTH;
				canvas.height = STANDARD_CARD_HEIGHT;
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

				const brightness = getAverageBrightness(image);
				ctx.fillStyle = brightness > 128 ? "#4c956c" : "#fefde2";

				ctx.font = "22px Orpheus Pro";
				ctx.textAlign = "center";
				ctx.shadowColor = "rgba(0,0,0,0.25)"; // Add shadow color
				ctx.shadowOffsetX = 1; // Add shadow offset
				ctx.shadowOffsetY = 0; // Add shadow blur

				const drawText = (text, x, y, lineHeight, fitWidth) => {
					const words = text.split(" ");
					let line = "";
					for (let n = 0; n < words.length; n++) {
						const testLine = line + words[n] + " ";
						const metrics = ctx.measureText(testLine);
						const testWidth = metrics.width;
						if (testWidth > fitWidth && n > 0) {
							ctx.fillText(line, x, y);
							line = words[n] + " ";
							y += lineHeight;
						} else {
							line = testLine;
						}
					}
					ctx.fillText(line, x, y);
				};

				const maxLineWidth = canvas.width * 0.8;
				const lineHeight = 30;
				const displayMessage = message.slice(0, 75);
				const displayName = name.slice(0, 50);

				drawText(displayMessage, canvas.width / 2, canvas.height / 2 - lineHeight, lineHeight, maxLineWidth);
				if (displayName) {
					drawText(`- ${displayName}`, canvas.width / 2, canvas.height / 2 + 3 * lineHeight, lineHeight, maxLineWidth);
				}
			}
		};

		drawCanvas();
	}, [selectedCard, message, name]);

	const submitSelection = async () => {
		if (Platform.OS === "web" && canvasRef.current) {

			const imageDataURL = canvasRef.current.toDataURL("image/png");
			// Convert data URL to blob
			const fetchResponse = await fetch(imageDataURL);
			const blob = await fetchResponse.blob();
			const uniqueFileName = `eCards/${Date.now()}-${selectedCard.cardName}.png`; // Adjust the naming convention as needed
			const storage = getStorage(app);
			const storageRef = ref(storage, uniqueFileName);

			try {
				await uploadBytes(storageRef, blob);
				const backImageUrl = await getDownloadURL(storageRef);
				let frontImageUrl = selectedCard.frontStorageURL;
				onSelect({ backImageUrl, frontImageUrl, name, message });
			} catch (error) {
				console.error("Error uploading eCard back image:", error);
				alert("Error uploading eCard, please try again later.");
			}
		}
	};

	return (
		<Modal visible={isVisible} animationType="fade" transparent={true}>
			<View style={styles.modalView}>

				<Pressable style={[styles.arrowButton, styles.arrowLeft]} onPress={() => scroll('left')}>
					<Ionicons name="arrow-back" size={24} color="black" />
				</Pressable>
				<ScrollView
					ref={scrollViewRef}
					horizontal={true}
					style={[styles.cardScrollView, isCardSelected && styles.shrunkCardScrollView]} // Conditionally apply styles
					contentContainerStyle={styles.scrollViewContent}
				>
					{eCards.map((card, index) => (
						<Pressable key={index} onPress={() => handleCardSelect(card)}>
							<Image source={card.front} style={selectedCard === card ? styles.selectedCard : styles.card} />
						</Pressable>
					))}
				</ScrollView>
				<Pressable style={[styles.arrowButton, styles.arrowRight]} onPress={() => scroll('right')}>
					<Ionicons name="arrow-forward" size={24} color="black" />
				</Pressable>

				{isCardSelected && (
					<View style={styles.selectedCardPreview}>
						<Pressable style={styles.clearButton} onPress={clearSelectedCard}>
							<Ionicons name="close" size={20} color="white" />
						</Pressable>
						{Platform.OS === "web" && selectedCard && <canvas ref={canvasRef} style={styles.canvas}></canvas>}
					</View>
				)}

				<TextInput style={styles.input} placeholder="Your Name" value={name} onChangeText={setName} maxLength={50} />
				<TextInput style={[styles.input]} placeholder="Short Message" value={message} multiline={true} onChangeText={setMessage} maxLength={75} />
				<Pressable style={styles.button} onPress={submitSelection}>
					<Text style={styles.buttonText}>Select and Sign</Text>
				</Pressable>
				<Pressable style={styles.closeButton} onPress={onClose}>
					<Text style={styles.buttonText}>Close</Text>
				</Pressable>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalView: {
		width: "100%",
		height: "90%",
		marginTop: 20,
		backgroundColor: "#fefde2",
		borderRadius: 20,
		padding: 30,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	cardScrollView: {
		width: "100%",
		marginBottom: 20,
	},
	shrunkCardScrollView: {
		height: 100, // Adjust the height as needed
	},
	scrollViewContent: {
		paddingHorizontal: 20,
	},
	arrowButton: {
		position: 'absolute',
		top: '50%',
		zIndex: 1,
		backgroundColor: "#fefde2",
	},
	arrowLeft: {
		left: 10,
	},
	arrowRight: {
		right: 10,
	},
	card: {
		width: STANDARD_CARD_WIDTH * 0.8, // Shrink card width
		height: STANDARD_CARD_HEIGHT * 0.8, // Shrink card height
		resizeMode: "contain",
		margin: 5,
	},
	selectedCard: {
		width: STANDARD_CARD_WIDTH * 0.8, // Shrink selected card width
		height: STANDARD_CARD_HEIGHT * 0.8, // Shrink selected card height
		resizeMode: "contain",
		margin: 5,
		borderWidth: 2,
		borderColor: "#4c956c",
	},
	selectedCardPreview: {
		position: 'relative',
		width: STANDARD_CARD_WIDTH,
		height: STANDARD_CARD_HEIGHT,
		marginTop: 10,
		marginBottom: 30,
	},
	clearButton: {
		position: 'absolute',
		top: 10,
		left: 10,
		zIndex: 1,
		backgroundColor: 'red',
		borderRadius: 15,
		padding: 7.5,
	},
	canvas: {
		width: STANDARD_CARD_WIDTH,
		height: STANDARD_CARD_HEIGHT,
	},
	input: {
		width: "100%",
		padding: 10,
		marginBottom: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 5,
	},
	button: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 5,
	},
	closeButton: {
		marginTop: 15,
		backgroundColor: "red",
		padding: 10,
		borderRadius: 5,
	},
	buttonText: {
		color: "white",
	},
});

export default ECardSelectionModal;