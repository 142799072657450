import React, { useState, useRef, useEffect } from "react";
import { View, TouchableOpacity, Image, Text, StyleSheet, Dimensions, Pressable } from "react-native";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../../firebase";



const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;


const PictureFrame = ({ initialImages = [], onImagesUpdated, isEditMode, onRemoveImage }) => {
	const [images, setImages] = useState(initialImages);
	const fileInputRef = useRef(null);

	useEffect(() => {
		// Check if initialImages has actually changed
		if (!arraysEqual(initialImages, images)) {
			setImages(initialImages);
		}
	}, [initialImages]);

	// Helper function to check if two arrays are equal
	function arraysEqual(arr1, arr2) {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	}

	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = async (event) => {
		console.log(event); // Log the entire event
		const files = Array.from(event.target.files);
		console.log(files); // Check what files contaian

		if (files.length === 0) {
			console.log("No files selected.");
			return;
		}

		const newImages = [...images];

		const uploadPromises = files.map(async (file) => {
			const uniqueFileName = `pictureFrameImages/${Date.now()}-${file.name}`;
			const storageRef = ref(getStorage(app), uniqueFileName);

			await uploadBytes(storageRef, file);
			const url = await getDownloadURL(storageRef);
			newImages.push(url);
		});

		try {
			await Promise.all(uploadPromises);
			setImages(newImages);
			onImagesUpdated(newImages);
		} catch (error) {
			console.error("Error uploading images:", error);
		}
	};

	const handleRemoveImage = (imageToRemove) => {
		console.log("Removing image:", imageToRemove); // Changed from alert to console.log
		const updatedImages = images.filter(image => image !== imageToRemove);
		setImages(updatedImages);
		onImagesUpdated(updatedImages);
	};

	const renderImages = () => {
		return images.map((image, index) => (
			<View key={index} style={styles.imageContainer}>
				<Image source={{ uri: image }} style={styles.imageStyle(index)} />
				<Pressable onPress={() => handleRemoveImage(image)}>
					<Text style={styles.removeButton}>Remove</Text>
				</Pressable>
			</View>
		));
	};

	return (
		<View style={styles.container}>
			<View style={styles.frame}>{renderImages()}</View>
			{isEditMode && (
				<TouchableOpacity onPress={handleUploadClick} style={styles.uploadButton}>
					<Text>Upload Image</Text>
				</TouchableOpacity>
			)}
			<input multiple type="file" onChange={handleFileChange} accept="image/*" style={{ display: "none" }} ref={fileInputRef} />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
		justifyContent: "center",
		marginTop: 20, // Adjust as needed
		paddingHorizontal: "auto",
	},
	frame: {
		flexDirection: "row", // Arrange images in a row
		position: "relative",
	},
	imageStyle: (index) => ({
		width: 500, // Increase size
		height: 500, // Increase size
		resizeMode: "cover",
		borderWidth: 4, // Adjust border width as needed
		borderColor: "white", // Adjust border color as needed
		marginLeft: -15, // Adjust overlap as needed
		transform: [{ rotate: `${index % 2 === 0 ? "-5deg" : "5deg"}` }], // Alternate tilt
	}),
	uploadButton: {
		// Style your upload button as needed
		padding: 10,
		backgroundColor: "#FEFCE0",
		borderRadius: 5,
		marginTop: 20, // Adjust as needed
	},
	imageContainer: {
		position: "relative",
	},
	image: {
		width: 500,
		height: 500,
	},
	removeButton: {
		position: "absolute",
		top: 10,
		right: 10,
		backgroundColor: "red",
		color: "white",
		padding: 5,
		borderRadius: 5,
	},
});

export default PictureFrame;