import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../firebase"; // Assuming this exports your Firebase app instance

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    // alert("Attempting to get user data from Firebase Auth...");
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // alert("User signed in. Fetching Firestore user data...");
        const userDocRef = doc(db, "users", user.email);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setCurrentUser(user);
          setCurrentUserData(userData);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('currentUserData', JSON.stringify(userData));
          // alert(`Welcome ${userData.firstName}`);
          // alert("User data fetched and cached.");
        } else {
          console.log("No Firestore user data found");
          // alert("No Firestore user data found.");
        }
      } else {
        setCurrentUser(null);
        setCurrentUserData(null);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserData');
        // alert("User signed out. Local cache cleared.");
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  useEffect(() => {
    // alert("Checking for cached user data...");
    const cachedUser = localStorage.getItem('currentUser');
    const cachedUserData = localStorage.getItem('currentUserData');
    if (cachedUser && cachedUserData) {
      const userData = JSON.parse(cachedUserData);
      setCurrentUser(JSON.parse(cachedUser));
      setCurrentUserData(userData);
      // alert(`Welcome ${userData.firstName}`);
      // alert("Cached user data found and set.");
    } else {
      // alert("No cached user data found.");
    }
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, currentUserData, setCurrentUser, setCurrentUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);