import React, { useState, useRef, useEffect, useContext } from "react";
import { Modal, View, Text, Image, TouchableOpacity, Dimensions, StyleSheet, ImageBackground, TextInput, Button } from "react-native";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, updateDoc, getFirestore } from "firebase/firestore"; // Import Firestore to save image URL
import Draggable from "react-draggable";
import exampleBackground1 from "../assets/exBackground1.jpg";
import exampleBackground2 from "../assets/exBackground2.jpg";
import exampleBackground3 from "../assets/exBackground3.jpg";
import exampleBackground4 from "../assets/exBackground4.jpg";
import exampleBackground5 from "../assets/exBackground5.jpg";
import exampleBackground6 from "../assets/exBackground6.jpg";
import exampleBackground7 from "../assets/exBackground7.jpg";
import examplePhoto1 from "../assets/exPhoto1.jpg";
import examplePhoto2 from "../assets/exPhoto2.jpg";
import { SketchPicker } from 'react-color'; // Import a color picker library
import { useBackground } from '../Screens/Helpers/BackgroundContext';
import Cropper from 'react-easy-crop'; // Import the cropper

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const GraduationCard = ({ pageOwner, currentUserData, isVisitorView }) => {
	const db = getFirestore();
	const { backgroundImage, setBackgroundImage } = useBackground();

	// File input references
	const topRightImageRef = useRef(null);
	const bottomLeftImageRef = useRef(null);
	const [updatedPageOwner, setUpdatedPageOwner] = useState(pageOwner);
	const backgroundImageRef = useRef(null);
	const [textColor, setTextColor] = useState(pageOwner.gradCardTextColor || '#fff'); // Initialize with saved text color or default
	const [selectedTextColor, setSelectedTextColor] = useState(pageOwner.gradCardTextColor || '#fff'); // Initialize with saved text color or default
	const [feedbackMessage, setFeedbackMessage] = useState('');
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [imageToCrop, setImageToCrop] = useState(null); // New state for the image to crop
	const [isCropping, setIsCropping] = useState(false); // State to control cropping modal
	const [topRightImage, setTopRightImage] = useState(null); // State for top right image
	const [bottomLeftImage, setBottomLeftImage] = useState(null); // State for bottom left image
	const [isLoading, setIsLoading] = useState(false); // New state for loading
	const [position, setPosition] = useState(null); // New state for position
	const [cropperKey, setCropperKey] = useState(0); // New state for forcing re-render
	const [originalFile, setOriginalFile] = useState(null); // New state to store the original file

	const exampleBackgrounds = [
		{ uri: exampleBackground1, label: "Background 1" },
		{ uri: exampleBackground2, label: "Background 2" },
		{ uri: exampleBackground3, label: "Background 3" },
		{ uri: exampleBackground4, label: "Background 4" },
		{ uri: exampleBackground5, label: "Background 5" },
		{ uri: exampleBackground6, label: "Background 6" },
		{ uri: exampleBackground7, label: "Background 7" },
	];

	// New state for managing the popup visibility
	const [showPopup, setShowPopup] = useState(false);
	const [isBackgroundSelectorVisible, setIsBackgroundSelectorVisible] = useState(false);
	const [backgroundImageUrlInput, setBackgroundImageUrlInput] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		console.log("Updated page owner:", updatedPageOwner);
	}, [updatedPageOwner]);

	// Function to handle image upload and set for cropping
	const handleImageUpload = async (file, pos, inputRef) => {
		if (file) {
			console.log("File selected:", file.name);
			const reader = new FileReader();
			reader.onloadend = () => {
				console.log("File read completed:", file.name);
				if (originalFile && file.name === originalFile.name && file.size === originalFile.size) {
					console.log("Same file selected, allowing re-cropping");
					// If the same file is selected, allow re-cropping
					setImageToCrop(null); // Reset the imageToCrop state
					setTimeout(() => {
						setImageToCrop(reader.result);
						setIsCropping(true);
						setCrop({ x: 0, y: 0 });
						setZoom(1);
						setPosition(pos);
						setCropperKey(prevKey => prevKey + 1); // Change the key to force re-render
						console.log("Re-cropping setup completed");
					}, 0); // Use a timeout to ensure state reset
				} else {
					console.log("New file selected, updating original file state");
					// If a new file is selected, update the original file state
					setOriginalFile(file);
					setImageToCrop(null); // Reset the imageToCrop state
					setTimeout(() => {
						setImageToCrop(reader.result); // Set the image to crop
						setIsCropping(true); // Open cropping modal
						setCrop({ x: 0, y: 0 }); // Reset crop position
						setZoom(1); // Reset zoom level
						setPosition(pos); // Set the position for cropping
						setCropperKey(prevKey => prevKey + 1); // Change the key to force re-render
						console.log("New cropping setup completed");
					}, 0); // Use a timeout to ensure state reset
				}
				// Clear the file input value to allow re-selecting the same file
				if (inputRef && inputRef.current) {
					inputRef.current.value = '';
				}
			};
			reader.readAsDataURL(file);
		}
	};

	// Function to handle cropping and uploading
	const handleCropComplete = async () => {
		if (!position) return; // Ensure position is defined

		setIsLoading(true); // Start loading
		console.log("Starting crop process...");

		try {
			console.log("Getting cropped image...");
			const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels);
			
			console.log("Uploading cropped image...");
			const storage = getStorage();
			const filename = `cropped_${pageOwner.uid}_${Date.now()}.jpg`;
			const fileRef = storageRef(storage, `graduationImages/${filename}`);

			// Delete the previous image if it exists
			if (position === 'topRight' && topRightImage) {
				const previousImageRef = storageRef(storage, topRightImage);
				await deleteObject(previousImageRef);
				console.log("Deleted previous top right image.");
			} else if (position === 'bottomLeft' && bottomLeftImage) {
				const previousImageRef = storageRef(storage, bottomLeftImage);
				await deleteObject(previousImageRef);
				console.log("Deleted previous bottom left image.");
			}

			// Upload the new cropped image
			const blob = await fetch(croppedImage).then(res => res.blob());
			await uploadBytes(fileRef, blob);
			const newImageUrl = await getDownloadURL(fileRef);
			console.log("Uploaded new image and got URL:", newImageUrl);

			// Update Firestore with the new image URL
			const userDocRef = doc(db, "users", pageOwner.email);
			if (position === 'topRight') {
				setTopRightImage(newImageUrl);
				await updateDoc(userDocRef, {
					topRightGradCardImage: newImageUrl,
				});
				setUpdatedPageOwner(prev => ({ ...prev, topRightGradCardImage: newImageUrl }));
			} else {
				setBottomLeftImage(newImageUrl);
				await updateDoc(userDocRef, {
					bottomLeftGradCardImage: newImageUrl,
				});
				setUpdatedPageOwner(prev => ({ ...prev, bottomLeftGradCardImage: newImageUrl }));
			}

			console.log("Crop process completed successfully.");
		} catch (error) {
			console.error("Error uploading cropped image:", error);
			alert("Failed to upload cropped image. Please try again.");
		} finally {
			setIsLoading(false); // End loading
			setIsCropping(false); // Close modal
		}
	};

	// Function to get cropped image as a blob
	const getCroppedImg = (imageSrc, pixelCrop) => {
		return new Promise((resolve, reject) => {
			const image = new window.Image(); // Use the standard HTML Image constructor
			image.src = imageSrc;
			image.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = pixelCrop.width; // Ensure pixelCrop is defined
				canvas.height = pixelCrop.height; // Ensure pixelCrop is defined
				ctx.drawImage(
					image,
					pixelCrop.x,
					pixelCrop.y,
					pixelCrop.width,
					pixelCrop.height,
					0,
					0,
					pixelCrop.width,
					pixelCrop.height
				);
				canvas.toBlob((blob) => {
					resolve(URL.createObjectURL(blob)); // Create a URL for the cropped image
				}, 'image/jpeg');
			};
			image.onerror = (error) => reject(error);
		});
	};

	const handleBackgroundImageChange = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		const storage = getStorage();
		const filename = `background_${pageOwner.uid}_${Date.now()}`;
		const fileRef = storageRef(storage, `graduationBackgrounds/${filename}`);

		try {
			const uploadTaskSnapshot = await uploadBytes(fileRef, file);
			const newBackgroundImageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
			setBackgroundImage(newBackgroundImageUrl); // Update context

			// Update Firestore with the new background image URL
			const userDocRef = doc(db, "users", pageOwner.email); // Make sure to use the correct document ID
			await updateDoc(userDocRef, {
				gradCardBackgroundImage: newBackgroundImageUrl, // Use the correct field name as per your database schema
			});

			console.log("Background image successfully uploaded and Firestore updated.");
		} catch (error) {
			console.error("Error uploading background image:", error);
			alert("Failed to upload background image. Please try again.");
		}
	};

	const handleSelectExampleBackground = async (backgroundUri) => {
		setBackgroundImage(backgroundUri); // Set the chosen example image as the new background

		// Assuming you want to update Firestore with this new background
		const userDocRef = doc(db, "users", pageOwner.email);
		await updateDoc(userDocRef, {
			gradCardBackgroundImage: backgroundUri,
		});
	};

	// Function to handle background change via URL
	const handleBackgroundChangeByUrl = async () => {
		if (!backgroundImageUrlInput) {
			setErrorMessage('Please enter a valid URL.');
			return;
		}
		try {
			setBackgroundImage(backgroundImageUrlInput); // Update context with the new URL

			// Update Firestore with the new background image URL
			const userDocRef = doc(db, "users", pageOwner.email);
			await updateDoc(userDocRef, {
				gradCardBackgroundImage: backgroundImageUrlInput,
			});
			console.log("Background image URL updated in Firestore.");
			setBackgroundImageUrlInput(''); // Reset input field
			setIsBackgroundSelectorVisible(false); // Close the selector
			setErrorMessage(''); // Clear any error messages
		} catch (error) {
			console.error("Error updating background image URL:", error);
			setErrorMessage('Failed to update background image. Please try again.');
		}
	};

	// Function to handle closing the background selector
	const handleCloseBackgroundSelector = () => {
		setIsBackgroundSelectorVisible(false);
		setErrorMessage(''); // Optionally reset any error messages
	};

	// Function to trigger file input click for top right image
	const handleChangeTopRightImage = () => {
		topRightImageRef.current.click();
	};

	// Function to trigger file input click for bottom left image
	const handleChangeBottomLeftImage = () => {
		bottomLeftImageRef.current.click();
	};

	const getAverageBrightness = async (imageUrl) => {
		return new Promise((resolve, reject) => {
			// Use the standard HTML Image constructor
			const img = new window.Image();
			img.crossOrigin = "Anonymous"; // This might be needed depending on the image's origin
			img.src = imageUrl;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0);
				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
				const data = imageData.data;
				let total = 0;
				let count = 0;
				for (let i = 0; i < data.length; i += 4) {
					// Calculate the average brightness of the red, green, and blue channels
					let avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
					total += avg;
					count++;
				}
				resolve(total / count);
			};
			img.onerror = (error) => reject(error);
		});
	};

	// Example function to set text color based on background brightness
	const setTextColorsBasedOnBackground = async (backgroundImageUri) => {
		if (pageOwner.gradCardTextColor) {
			setTextColor(pageOwner.gradCardTextColor);
			return;
		}
		const brightness = await getAverageBrightness(backgroundImageUri);
		if (brightness > 128) {
			setTextColor('#4c956c');
		} else {
			setTextColor('#e4e4e4');
		}
	};

	// Text Color Upload and Selection
	const applyTextColor = async () => {
		const userDocRef = doc(db, "users", pageOwner.email);
		try {
			await updateDoc(userDocRef, {
				gradCardTextColor: selectedTextColor,
			});
			console.log("Text color successfully updated in Firestore.");
			setFeedbackMessage('Text color applied successfully!');
			setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
		} catch (error) {
			console.error("Error updating text color:", error);
			alert("Failed to update text color. Please try again.");
		}
	};

	function handleStart(e, data) {
		console.log('Drag start:', data);
	}

	function handleDrag(e, data) {
		console.log('Dragging:', data);
	}

	function handleStop(e, data) {
		console.log('Drag stop:', data);
	}

	// Call this function whenever the background image changes
	useEffect(() => {
		if (backgroundImage) {
			setTextColorsBasedOnBackground(backgroundImage);
		}
	}, [backgroundImage]);

	return (
		<View style={styles.card}>
			<ImageBackground source={{ uri: backgroundImage }} style={styles.backgroundImage}>
				<input type="file" ref={backgroundImageRef} style={{ display: "none" }} onChange={handleBackgroundImageChange} accept="image/*" />
				<input type="file" ref={topRightImageRef} style={{ display: "none" }} onChange={(e) => handleImageUpload(e.target.files[0], 'topRight', topRightImageRef)} accept="image/*" />
				<input type="file" ref={bottomLeftImageRef} style={{ display: "none" }} onChange={(e) => handleImageUpload(e.target.files[0], 'bottomLeft', bottomLeftImageRef)} accept="image/*" />

				<Text style={[styles.classOfText, { color: textColor }]}>Class of</Text>
				<Text style={[styles.classOfYear, { color: textColor }]}>{pageOwner.graduationYear}</Text>

				{/* Display the top right image */}
					<Image 
						source={{ uri: updatedPageOwner?.topRightGradCardImage || examplePhoto1 }} 
						style={styles.topRightImageContainer} 
						resizeMode="cover" // Use cover for visitors
					/>

				{/* Display the bottom left image */}
					<Image 
						source={{ uri: updatedPageOwner?.bottomLeftGradCardImage || examplePhoto2 }} 
						style={styles.bottomLeftImageContainer} 
						resizeMode="cover" // Use cover for visitors
					/>

				<View style={styles.details}>
					<Text style={[styles.name, { color: textColor }]}>
						{pageOwner.firstName} {pageOwner.lastName}
					</Text>


					{pageOwner?.graduationDay ? (
						<>
							<Text style={[styles.dateAndOrganization, { color: textColor }]}>
								Graduation Date: {pageOwner.graduationMonth} {pageOwner.graduationDay}, {pageOwner.graduationYear}
							</Text>
						</>
					) : (
						<>
							<Text style={[styles.dateAndOrganization, { color: textColor }]}>
								Graduation Date: {pageOwner.graduationMonth} {pageOwner.graduationYear}
							</Text>
						</>
					)}


					<Text style={[styles.dateAndOrganization, { color: textColor }]}>{pageOwner.organization.name}</Text>
				</View>



				{currentUserData?.uid === pageOwner.uid && !isVisitorView && (
					<>
						{/* Change Background Button */}
						<TouchableOpacity style={styles.uploadBackgroundButton} onPress={() => setIsBackgroundSelectorVisible(true)}>
							<Text style={styles.uploadBackgroundButtonText}>Announcement Background & Settings</Text>
						</TouchableOpacity>

						{/* Buttons for changing top-right and bottom-left images */}
						<TouchableOpacity style={[styles.changeImageButton, styles.changeImageBottomLeftButton]} onPress={handleChangeBottomLeftImage}>
							<Text style={styles.changeImageButtonText}>Change Bottom Left Image</Text>
						</TouchableOpacity>
						<TouchableOpacity style={[styles.changeImageButton, styles.changeImageTopRightButton]} onPress={handleChangeTopRightImage}>
							<Text style={styles.changeImageButtonText}>Change Top Right Image</Text>
						</TouchableOpacity>

						{/* Background Selector Modal or Visible Section */}
						{isBackgroundSelectorVisible && (
							<View style={styles.backgroundSelector}>
								{/* Close Button */}
								<TouchableOpacity style={styles.closeButton} onPress={handleCloseBackgroundSelector}>
									<Text style={styles.closeButtonText}>X</Text>
								</TouchableOpacity>

								{/* Example Backgrounds Selection */}
								<View style={styles.exampleBackgroundsContainer}>
									{exampleBackgrounds.map((bg, index) => (
										<TouchableOpacity key={index} onPress={() => handleSelectExampleBackground(bg.uri)} style={styles.exampleBackground}>
											<Text style={styles.exampleBackgroundText}>{bg.label}</Text>
										</TouchableOpacity>
									))}
								</View>

								{/* Input for Background URL */}
								<TextInput
									style={styles.urlInput}
									onChangeText={setBackgroundImageUrlInput}
									value={backgroundImageUrlInput}
									placeholder="Enter background image URL"
								/>
								{backgroundImageUrlInput && (
									<TouchableOpacity style={styles.greenButton} onPress={handleBackgroundChangeByUrl}>
										<Text style={styles.buttonText}>Upload From URL</Text>
									</TouchableOpacity>
								)}
								<TouchableOpacity style={styles.greenButton} onPress={() => backgroundImageRef.current.click()}>
									<Text style={styles.buttonText}>Upload From Device</Text>
								</TouchableOpacity>
								{errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
								{/* Color Picker for Text Color */}
								<Text style={[styles.modalHeader, { color: '#fff' }]}>Select Text Color:</Text>
								<SketchPicker
									color={selectedTextColor}
									onChangeComplete={(color) => setSelectedTextColor(color.hex)}
									onChange={(color) => setTextColor(color.hex)} // Update text color dynamically
								/>
								<TouchableOpacity style={styles.greenButton} onPress={applyTextColor}>
									<Text style={styles.buttonText}>Apply Text Color</Text>
								</TouchableOpacity>
								{feedbackMessage ? <Text style={styles.feedbackMessage}>{feedbackMessage}</Text> : null}
							</View>
						)}
					</>
				)}

				{isCropping && (
					<Modal visible={isCropping} transparent={false} animationType="slide">
						<View style={styles.instructionsModalContainer}>
							<Text style={styles.instructionText}>Scroll to zoom in or out.</Text>
							<Cropper
								key={cropperKey} // Use the key to force re-render
								image={imageToCrop}
								crop={crop}
								zoom={zoom}
								aspect={2/1} // Set aspect ratio to 1:2
								onCropChange={setCrop}
								onZoomChange={setZoom}
								onCropComplete={(croppedArea, croppedAreaPixels) => {
									setCroppedAreaPixels(croppedAreaPixels);
								}}
								style={{ width: '100%', height: '100%' }} // Ensure Cropper takes full size
							/>

							{/* Loading overlay */}
							{isLoading && (
								<View style={styles.loadingOverlay}>
									<Text style={styles.loadingText}>Uploading Image...</Text>
								</View>
							)}

							<View style={styles.buttonContainer}>
								<Button style={styles.button} title="Cancel" onPress={() => setIsCropping(false)} />
								<Button style={styles.button} title="Confirm Crop" onPress={handleCropComplete} />
								
							</View>
						</View>
					</Modal>
				)}
			</ImageBackground>
		</View>
	);
};

const styles = StyleSheet.create({
	topRightImageContainer: {
		width: isMobile ? "100%" : isTablet ? "70%" : isSmallDesktop ? "50%" : "50%", // Adjust to your needs
		height: isMobile ? "50%" : isTablet ? "60%" : isSmallDesktop ? "70%" : "60%", // Adjust to your needs
		position: "absolute",
		top: isMobile ? 0 : isTablet ? 15 : isSmallDesktop ? 30 : 15, // Adjust to your needs
		left: isMobile ? "25%" : "50%",
		borderWidth: 5,
		borderColor: "#e4e4e4",
		transform: [{ rotate: "4deg" }], // Slight angle to the right
		zIndex: 96,
		shadowOffset: { width: 7, height: 5 },
		shadowRadius: 6,
		shadowColor: "rgba(0, 0, 0, 0.9)",
		overflow: "hidden",
	},
	bottomLeftImageContainer: {
		width: isMobile ? "100%" : isTablet ? "70%" : isSmallDesktop ? "55%" : "55%",
		height: isMobile ? "50%" : isTablet ? "60%" : isSmallDesktop ? "70%" : "60%",
		position: "absolute",
		bottom: 0,
		left: isMobile ? 0 : isTablet ? 20 : isSmallDesktop ? 40 : 20,
		borderWidth: 5,
		borderColor: "#e4e4e4",
		transform: [{ rotate: "-2deg" }], // Slight angle to the right
		shadowOffset: { width: 7, height: 3 },
		shadowRadius: 6,
		shadowColor: "rgba(0, 0, 0, 0.4)",
		overflow: "hidden",

	},
	topRightImage: {
		width: "100%",
		height: "100%",
		position: "absolute",
		transform: [{ rotate: "4deg" }], // Slight angle to the right
		zIndex: 96,
		overflow: "hidden",
	},
	bottomLeftImage: {
		width: "100%",
		height: "100%",
		position: "absolute",
		transform: [{ rotate: "-2deg" }], // Slight angle to the right
		overflow: "hidden",
		// zIndex: 97
	},
	uploadBackgroundButton: {
		position: "relative",
		width: isMobile ? "100%" : isTablet ? "70%" : isSmallDesktop ? "50%" : "50%",
		height: isMobile ? "5%" : isTablet ? "5%" : isSmallDesktop ? "5%" : "5%",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0,0,0,0.6)",
		zIndex: 95,
		top: 0, // Position at the top
		left: 0, // Position to the left
		borderRadius: 5, // Rounded corners
		zIndex: 99, // Ensure it's above other elements
		padding: 10,
	},
	uploadBackgroundButtonText: {
		color: "#fff",
		fontSize: isMobile ? 14 : isTablet ? 16 : isSmallDesktop ? 18 : 20,
	},
	backgroundSelector: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0,0,0,0.5)',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1001
	},
	urlInput: {
		borderWidth: 1,
		borderColor: '#ccc',
		padding: 10,
		margin: 10,
		width: isMobile ? '30%' : isTablet ? '40%' : isSmallDesktop ? '50%' : '60%',
		backgroundColor: '#fff',
	},
	card: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		overflow: "hidden",
		borderWidth: 0,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 30,
		borderColor: "#e4e4e4",
		marginBottom: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 25,
		marginTop: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 25,
		shadowOffset: { width: 0, height: 6 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
	},
	backgroundImage: {
		width: "100%",
		height: "100%",
		resizeMode: "cover", // This ensures the background image covers the entire space
	},
	content: {
		flex: 1,
		padding: 20,
		alignItems: "center",
		justifyContent: "space-between", // Distribute space evenly between the items
	},
	classOfText: {
		alignSelf: "flex-start", // Align to the left
		fontSize: isMobile ? 30 : isTablet ? 50 : isSmallDesktop ? 50 : 70,
		color: "#e4e4e4",
		fontFamily: "Snell Roundhand",
		fontWeight: "bold",
		position: "absolute",
		top: isMobile ? 60 : isTablet ? 80 : isSmallDesktop ? 30 : 40,
		left: isMobile ? 120 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.9◊5)",
		zIndex: 99,
	},
	classOfYear: {
		alignSelf: "flex-start", // Align to the left
		fontSize: isMobile ? 100 : isTablet ? 200 : isSmallDesktop ? 175 : 250,
		color: "#e4e4e4",
		fontFamily: "Snell Roundhand",
		fontWeight: "bold",
		position: "absolute",
		top: isMobile ? 65 : isTablet ? 70 : isSmallDesktop ? 25 : 40,
		left: isMobile ? 50 : isTablet ? 30 : isSmallDesktop ? 40 : 50,
		zIndex: 99,
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 3,
		textShadowColor: "rgba(0, 0, 0, 0.5)",
	},
	details: {
		alignItems: "center", // Align text items to center
		position: "absolute",
		bottom: isMobile ? 50 : isTablet ? 70 : isSmallDesktop ? 40 : 50,
		right: isMobile ? 25 : isTablet ? 70 : isSmallDesktop ? 110 : 110,
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 3,
		textShadowColor: "rgba(0, 0, 0, 0.75)",
		zIndex: 99,
	},
	name: {
		fontSize: isMobile ? 50 : isTablet ? 90 : isSmallDesktop ? 70 : 110,
		color: "#e4e4e4",
		fontWeight: "bold",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 3,
		textShadowColor: "rgba(0, 0, 0, 0.75)",
	},
	dateAndOrganization: {
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 20 : 35,
		color: "#e4e4e4",
		fontFamily: "Bradley Hand",
		marginTop: isMobile ? 2 : isTablet ? 2 : isSmallDesktop ? 2 : 4, // Add space between the name and this text
		textShadow_offset: { width: 3, height: 5 },
		textShadow_radius: 6,
		textShadow_color: "rgba(0, 0, 0, 0.5)",
	},
	uploadButtonText: {
		color: "#fff",
		fontSize: 16,
	},
	exampleBackgroundsContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginTop: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 25,
	},
	exampleBackground: {
		margin: 5,
		padding: 10,
		backgroundColor: '#fff',
		borderRadius: 5,
	},
	exampleBackgroundText: {
		color: '#000',
	},
	greenButton: {
		backgroundColor: '#4CAF50', // Green background
		padding: 10,
		margin: 10,
		borderRadius: 5,
	},
	buttonText: {
		color: '#fff',
		textAlign: 'center',
	},
	errorText: {
		color: 'red',
		marginTop: 10,
	},
	closeButton: {
		position: 'absolute',
		top: 25,
		left: 25,
		backgroundColor: '#ff4545', // Red background for the close button
		width: 50,
		height: 50,
		borderRadius: 100, // Circular button
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1002, // Ensure it's above other elements
	},
	closeButtonText: {
		color: '#fff',
		fontWeight: 'bold',
		fontSize: 16,
	},
	changeImageButton: {
		padding: 10,
		backgroundColor: "rgba(0,0,0,0.6)",
		position: 'absolute', // Use absolute positioning to place the buttons
		color: "#fff",
	},
	changeImageTopRightButton: {
		right: 0, // Adjust as needed
		bottom: 0, // Position at the bottom right corner
		width: isMobile ? "40%" : isTablet ? "50%" : isSmallDesktop ? "50%" : "50%",
	},
	changeImageBottomLeftButton: {
		left: 0, // Adjust as needed
		bottom: 0, // Position at the bottom right corner
		width: isMobile ? "40%" : isTablet ? "50%" : isSmallDesktop ? "50%" : "50%",
		zIndex: 99,
	},
	changeImageButtonText: {
		color: "#fff",
		textAlign: "center",
		fontSize: isMobile ? 14 : isTablet ? 16 : isSmallDesktop ? 16 : 20,
	},
	feedbackMessage: {
		color: 'green',
		fontSize: 16,
		marginTop: 10,
	},
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
		padding: 20,
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 20,
		position: 'absolute', // Positioning the buttons absolutely
		bottom: 20, // Position them at the bottom of the modal
		left: 20, // Add some left margin
		right: 20, // Add some right margin
	},
	button: {
		flex: 1,
		marginHorizontal: 10,
		padding: 10,
		backgroundColor: '#4CAF50', // Green background color
		borderRadius: 5,
		color: '#fff', // White text color
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	instructionsModalContainer: {
		flex: 1,
		justifyContent: 'flex-start', // Align items to the top
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.8)', // Optional: dark background for better visibility
		padding: 20,
		borderRadius: 10, // Rounded corners for the modal
		margin: 20, // Margin to prevent touching the edges
		width: "100%",
		height: "100%",
	},
	instructionText: {
		color: '#fff', // White text for visibility
		textAlign: 'center',
		fontSize: 18, // Adjust font size for better readability
		padding: 10,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		position: 'absolute', // Fixed position
		top: 20, // Position it at the top
		left: '50%', // Center horizontally
		transform: [{ translateX: -50 }], // Adjust for centering
		zIndex: 1001, // Ensure it is above other elements
	},
	loadingOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
		zIndex: 1000, // Ensure it overlays above other content
	},
	loadingText: {
		color: '#fff', // White text for visibility
		fontSize: 20,
	},
});
export default GraduationCard;