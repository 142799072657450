import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { useUser } from "../../Widgets/UserContext";

const fontFamilies = {
    Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const RSVP = ({ pageOwnerId, widgetIndex }) => {
    const db = getFirestore();
    const { currentUser } = useUser();
    const [rsvp, setRSVP] = useState("");
    const [counts, setCounts] = useState({ Going: 0, Maybe: 0, Decline: 0 });

    useEffect(() => {
        loadRSVPData();
    }, []);

    const loadRSVPData = async () => {
        const docRef = doc(db, "users", pageOwnerId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const rsvpData = data.pages?.eventsPageConfig?.rsvp || {};
            setCounts({
                Going: rsvpData.Going?.length || 0,
                Maybe: rsvpData.Maybe?.length || 0,
                Decline: rsvpData.Decline?.length || 0,
            });
            const userRSVP = rsvpData.Going?.includes(currentUser.uid)
                ? "Going"
                : rsvpData.Maybe?.includes(currentUser.uid)
                ? "Maybe"
                : rsvpData.Decline?.includes(currentUser.uid)
                ? "Decline"
                : "";
            setRSVP(userRSVP);
        }
    };

    const handleRSVP = async (option) => {
        const userDocRef = doc(db, "users", pageOwnerId);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const rsvpData = data.pages?.eventsPageConfig?.rsvp || { Going: [], Maybe: [], Decline: [] };

            // Remove user from all RSVP lists
            rsvpData.Going = rsvpData.Going.filter((uid) => uid !== currentUser.uid);
            rsvpData.Maybe = rsvpData.Maybe.filter((uid) => uid !== currentUser.uid);
            rsvpData.Decline = rsvpData.Decline.filter((uid) => uid !== currentUser.uid);

            // Add user to the selected RSVP list
            rsvpData[option].push(currentUser.uid);

            // Update Firestore
            await updateDoc(userDocRef, {
                "pages.eventsPageConfig.rsvp": rsvpData,
            });

            // Update local state
            setRSVP(option);
            setCounts({
                Going: rsvpData.Going.length,
                Maybe: rsvpData.Maybe.length,
                Decline: rsvpData.Decline.length,
            });
        }
    };

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            justifyContent: "space-around",
            paddingHorizontal: isMobile ? 5 : "auto", // Adjust padding for mobile
        },
        button: {
            padding: isMobile ? 10 : 30, // Adjust padding for mobile
            borderRadius: 15,
            backgroundColor: "#ccc",
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            position: "relative",
            marginHorizontal: isMobile ? 5 : 10, // Add margin for mobile
        },
        selectedButton: {
            padding: isMobile ? 10 : 30, // Adjust padding for mobile
            borderRadius: 15,
            backgroundColor: "grey",
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            position: "relative",
            marginHorizontal: isMobile ? 5 : 10, // Add margin for mobile
        },
        text: {
            fontFamily: fontFamilies.Bodoni72Smallcaps,
            fontWeight: "normal",
            fontSize: isMobile ? 14 : 20, // Adjust font size for mobile
            textAlign: "left",
            color: "#fff",
            paddingHorizontal: 10,
        },
        countBadge: {
            position: "absolute",
            top: -10,
            right: -10,
            backgroundColor: "red",
            borderRadius: 10,
            padding: 5,
            color: "#fff",
            fontSize: isMobile ? 10 : 16, // Adjust font size for mobile
        },
    });

    return (
        <View style={{ padding: 10 }}>
            <View style={styles.container}>
                <TouchableOpacity
                    onPress={() => handleRSVP("Going")}
                    style={[styles.button, rsvp === "Going" && { backgroundColor: "green" }]}
                >
                    <Text style={styles.text}>Going</Text>
                    <Text style={styles.countBadge}>{counts.Going}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => handleRSVP("Maybe")}
                    style={[styles.button, rsvp === "Maybe" && { backgroundColor: "beige" }]}
                >
                    <Text style={styles.text}>Maybe</Text>
                    <Text style={styles.countBadge}>{counts.Maybe}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => handleRSVP("Decline")}
                    style={[styles.button, rsvp === "Decline" && { backgroundColor: "red" }]}
                >
                    <Text style={styles.text}>Decline</Text>
                    <Text style={styles.countBadge}>{counts.Decline}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default RSVP;