import React from "react";
import { View, Text, ImageBackground, TextInput, TouchableOpacity, Alert } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { app } from "../firebase";
import { useUser } from "./UserContext";
import { Dimensions } from "react-native";
import Bodoni72Smallcaps from "../assets/fonts/Bodoni 72 Smallcaps Book.ttf";

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const Popup = ({ type, onClose, isAdminPage = false }) => {
	const { currentUser, setCurrentUser, setCurrentUserData } = useUser();
	const auth = getAuth(app);
	const db = getFirestore(app);
	const navigation = useNavigation();

	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [reEnteredPassword, setReEnteredPassword] = React.useState(""); // Added state for re-entered password

	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");

	const [loginError, setLoginError] = React.useState(false); // New state for tracking login errors

	const emailExists = async (email) => {
		const lowerCaseEmail = email.toLowerCase();
		const docRef = doc(db, "users", lowerCaseEmail);
		const docSnap = await getDoc(docRef);
		return docSnap.exists();
	};

	const createUserProfile = async (userId, userData) => {
		const lowerCaseEmail = userId.toLowerCase();
		try {
			await setDoc(doc(db, "users", lowerCaseEmail), userData);
			console.log("User profile created for", lowerCaseEmail);
			await updateLastLogin(lowerCaseEmail);
		} catch (error) {
			console.error("Error creating user profile for", lowerCaseEmail, ":", error);
		}
	};

	const updateLastLogin = async (userId) => {
		const lowerCaseEmail = userId.toLowerCase();
		try {
			await updateDoc(doc(db, "users", lowerCaseEmail), {
				lastLogin: new Date().toLocaleTimeString("en-US", { hour12: false }),
			});
			console.log("Last login updated for", lowerCaseEmail);
		} catch (error) {
			console.error("Error updating last login for", lowerCaseEmail, ":", error);
		}
	};

	const generateUniqueUid = async () => {
		let isUnique = false;
		let uid;

		while (!isUnique) {
			// Generate a random 5-digit number and prepend 'A'
			uid =
				"F" +
				Math.floor(Math.random() * 100000)
					.toString()
					.padStart(5, "0");

			// Check Firestore for uniqueness
			const docRef = doc(db, "users", uid);
			const docSnap = await getDoc(docRef);
			isUnique = !docSnap.exists(); // UID is unique if it doesn't exist in Firestore
		}

		return uid;
	};

	const handleSignUp = async () => {
		if (password !== reEnteredPassword) {
			alert("Passwords do not match. Please try again.");
			return;
		}
		const lowerCaseEmail = email.toLowerCase();
		var accountType = "Friends & Family";
		var newAccount = true;
		var organization = { name: "", code: "" };
		var profilePictureURL = "";
		var friends = {
			following: {},
			followers: {},
			requests: {}
		};
		var messages = {};

		try {
			const exists = await emailExists(lowerCaseEmail);
			if (exists) {
				alert("Email already in use, please login instead.");
				navigation.navigate("My Tassel - Welcome", { popupType: "Login" });
				return;
			}

			const uniqueUid = await generateUniqueUid(); // Generate a unique UID

			const userCredential = await createUserWithEmailAndPassword(auth, lowerCaseEmail, password);
			const user = userCredential.user;

			const userData = {
				uid: uniqueUid, // Use the generated unique UID
				email: lowerCaseEmail,
				firstName,
				lastName,
				newAccount,
				accountType,
				profilePictureURL,
				friends,
				organization,
				messages,
			};

			await createUserProfile(lowerCaseEmail, userData);
			// Here's the change: update both currentUser (auth user) and currentUserData (Firestore data)
			setCurrentUser(user); // Set the auth user
			setCurrentUserData(userData); // Set the Firestore user data
			handleLinkPress("Hub");
			onClose(true); // Close the popup with success
		} catch (error) {
			if (error.code === "auth/email-already-in-use") {
				alert("Email already in use", "The email address is already in use by another account.");
				return
			} else {
				alert("Signup Error", error.message);
				return
			}
			// onClose(false); // Close the popup with failure
		}
	};

	const handleSignIn = async () => {
		const lowerCaseEmail = email.toLowerCase();
		try {
			// Reset login error state on each attempt
			setLoginError(false);

			// Fetch user data to check hasConfirmedEmail
			const userDocRef = doc(db, "users", lowerCaseEmail);
			const userDocSnap = await getDoc(userDocRef);

			let loginEmail = lowerCaseEmail;
			// if (userDocSnap.exists() && userDocSnap.data().hasConfirmedEmail === false) {
			// 	loginEmail = userDocSnap.data().email?.toLowerCase() || userDocSnap.data().newEmail?.toLowerCase();
			// }

			const userCredential = await signInWithEmailAndPassword(auth, loginEmail, password);
			const user = userCredential.user;

			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				setCurrentUser(user); // Set the auth user
				setCurrentUserData(userData); // Set the Firestore user data
				await updateLastLogin(loginEmail);

				if (isAdminPage && userData?.accountType.toLowerCase() !== "admin") {
					alert("Access Denied", "You do not have permission to access the admin page.");
					// onClose(false); // Close the popup with failure
				} else {
					handleLinkPress(isAdminPage ? "Admin" : "Hub"); // Adjust "AdminPage" to your admin page route
					onClose(true); // Close the popup with success
				}
			} else {
				console.error("No such user data!");
				setLoginError(true);
				// onClose(false); // Close the popup with failure
			}
		} catch (error) {
			console.error("Sign in error:", error.message);
			setLoginError(true); // Set login error state on failure
			// onClose(false); // Close the popup with failure
		}
	};

	const handleAnonymousSignIn = () => {
		signInAnonymously(auth)
			.then((userCredential) => {
				const user = userCredential.user;
				handleLinkPress("Hub");
				onClose(true); // Close the popup with success
			})
			.catch((error) => {
				console.error("Sign in anonymously error:", error.message);
				// onClose(false); // Close the popup with failure
			});
	};

	const handleLinkPress = (pageName) => {
		navigation.navigate(pageName);
	};

	const handlePasswordReset = async () => {
		const lowerCaseEmail = email.toLowerCase();
		if (!lowerCaseEmail) {
			alert("Please enter your email address.");
			return;
		}

		try {
			await sendPasswordResetEmail(auth, lowerCaseEmail);
			alert("A link to reset your password has been sent to your email address.");
		} catch (error) {
			alert("Reset Password Error", error.message);
		}
	};

	const isDevelopment = process.env.NODE_ENV === "development";

	React.useEffect(() => {
	  if (isDevelopment) {
	    const testLogin = async () => {
	      try {
	        const testEmails = [
	          "Tjhawk27@gmail.com", // Trevor Hawkins
	          "trevorhawkinsofficial@gmail.com", // Cameron Brink
	          "trevorhawkins@makenoisemedia.io", // Sam Minix
	          "info@dsignable.com" // Tristan
	        ];
	        const password = "Newtjhawk27";
	        const lowerCaseEmail = testEmails[3].toLowerCase(); // Default to Jennifer

	        const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
	        const user = userCredential.user;

	        const userDocRef = doc(db, "users", lowerCaseEmail);
	        const userDocSnap = await getDoc(userDocRef);

	        if (userDocSnap.exists()) {
	          const userData = userDocSnap.data();
	          setCurrentUser(user); // Set the auth user
	          setCurrentUserData(userData); // Set the Firestore user data
	          navigation.navigate("Hub"); // Navigate to Hub
	        } else {
	          console.error("No such user data!");
	        }
	      } catch (error) {
	        console.error("Error logging in with test account", error);
	      }
	    };
	    // testLogin();
	  }
	}, []);
	

	return (
		<View style={{ ...styles.popupWrapper, borderRadius: 50, overflow: "hidden" }}>
			<ImageBackground source={require("../assets/Popup Background.png")} style={{ ...styles.popup, borderRadius: 50, overflow: "hidden" }}>
				<Text style={styles.popupTitle}>Welcome to MyTassel!</Text>

				{type === "register" && <Text style={styles.popupSubtitle}>Join MyTassel and make your graduation special. Create events, build registries, and connect with loved ones!</Text>}

				{type === "login" && <Text style={styles.popupSubtitle}>Sign in now to reconnect with your loved ones and continue planning out the perfect celebration! </Text>}

				<TextInput
					style={{
						...styles.popupInput,
						fontFamily: fontFamilies.Bodoni72Smallcaps,
						borderColor: loginError ? "red" : "#fefde2", // Change border color on error
						borderWidth: loginError ? 2 : 0,
					}}
					placeholder="Email"
					placeholderTextColor="#aaa"
					onChangeText={(text) => {
						setEmail(text.toLowerCase());
						if (loginError) setLoginError(false); // Reset error state when user starts typing
					}}
					value={email.toLowerCase()}
				/>

				<TextInput
					style={{
						...styles.popupInput,
						fontFamily: fontFamilies.Bodoni72Smallcaps,
						borderColor: loginError ? "red" : "#fefde2", // Change border color on error
						borderWidth: loginError ? 2 : 0,
					}}
					placeholder="Password"
					placeholderTextColor="#aaa"
					secureTextEntry={true}
					onChangeText={(text) => {
						setPassword(text);
						if (loginError) setLoginError(false); // Reset error state when user starts typing
					}}
					value={password}
				/>

				{type === "register" && (
					<View>
						<TextInput
							style={{ ...styles.popupInput, fontFamily: fontFamilies.Bodoni72Smallcaps }}
							placeholder="Re-enter Password"
							placeholderTextColor="#aaa"
							secureTextEntry={true}
							onChangeText={(text) => setReEnteredPassword(text)}
							value={reEnteredPassword}
						/>
						<TextInput style={{ ...styles.popupInput, fontFamily: fontFamilies.Bodoni72Smallcaps }} placeholder="First Name" placeholderTextColor="#aaa" onChangeText={(text) => setFirstName(text)} value={firstName} />

						<TextInput style={{ ...styles.popupInput, fontFamily: fontFamilies.Bodoni72Smallcaps }} placeholder="Last Name" placeholderTextColor="#aaa" onChangeText={(text) => setLastName(text)} value={lastName} />
					</View>
				)}

				{password !== reEnteredPassword && type === "register" && <Text style={styles.errorText}>Passwords do not match. Please try again.</Text>}

				<TouchableOpacity
					style={styles.popupButton}
					onPress={() => {
						if (type === "register") {
							handleSignUp();
						} else {
							handleSignIn();
						}
					}}>
					<Text style={styles.popupButtonText}>{type === "login" ? "Sign In" : "Sign Up"}</Text>
				</TouchableOpacity>

				{type === "login" && (
					<>
						<TouchableOpacity
							style={styles.bypass}
							onPress={() => {
								handleAnonymousSignIn();
							}}>
							<Text style={styles.bypassText}>Continue without signing in</Text>
						</TouchableOpacity>

						<TouchableOpacity onPress={handlePasswordReset}>
							<Text style={{ color: "#4c956c", marginTop: 10, paddingBottom: 10 }}>Forgot Password?</Text>
						</TouchableOpacity>
					</>
				)}

				<TouchableOpacity onPress={() => onClose(false)} style={styles.closeButton}>
					<Text style={styles.closeButtonText}>x</Text>
				</TouchableOpacity>
			</ImageBackground>
		</View>
	);
};

const styles = {
	popupWrapper: {
		flex: 1,
		position: "absolute",
		maxWidth: 1000,
		minWidth: isMobile ? 100 : isTablet ? 200 : isSmallDesktop ? 400 : 1000,
		minHeight: isMobile ? 100 : isTablet ? 200 : isSmallDesktop ? 400 : 500,
		justifyContent: "center",
		alignItems: "center",
		padding: 20,
		top: 0,
		borderRadius: isMobile ? 50 : isTablet ? 100 : isSmallDesktop ? 150 : 200, // Move borderRadius to popupWrapper
	},
	popup: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: isMobile ? 0 : isTablet ? 2 : isSmallDesktop ? 10 : 20,
		borderRadius: isMobile ? 50 : isTablet ? 100 : isSmallDesktop ? 150 : 200, // Add borderRadius to popup for responsiveness
		
	},
	popupTitle: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 26 : isTablet ? 50 : isSmallDesktop ? 80 : 100,
		fontWeight: "normal",
		color: "#fefde1",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 5,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		width: isMobile ? "50%" : isTablet ? "85%" : isSmallDesktop ? "90%" : 893,
		height: isMobile ? 115 : isTablet ? 100 : isSmallDesktop ? 220 : 247,
		paddingTop: isMobile ? 40 : isTablet ? 25 : isSmallDesktop ? 0 : 0,
		textAlign: "center",
	},
	popupSubtitle: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 24 : isSmallDesktop ? 28 : 30,
		fontWeight: "500",
		color: "#fefde2",
		lineHeight: isMobile ? 20 : isTablet ? 30 : isSmallDesktop ? 35 : 35,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		width: isMobile ? "75%" : isTablet ? "75%" : isSmallDesktop ? 731 : 731,
		height: 95,
		textAlign: "center",
	},
	popupInput: {
		width: isMobile ? "75%" : isTablet ? 500 : isSmallDesktop ? 600 : 674,
		height: 50,
		backgroundColor: "#fefde2",
		paddingHorizontal: 10,
		paddingVertical: 8,
		marginBottom: 20,
		borderRadius: 50,
	},
	popupButtonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
		fontWeight: "500",
		color: "#4c956c",
		lineHeight: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	popupButton: {
		backgroundColor: "#4c956c",
		paddingHorizontal: isMobile ? 75 : isTablet ? 100 : isSmallDesktop ? 125 : 150,
		paddingVertical: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 15 : 15,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
	},
	popupButtonText: {
		color: "white",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 14 : isTablet ? 16 : isSmallDesktop ? 18 : 18,
	},
	closeButton: {
		position: "absolute",
		top: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 20,
		left: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
	},
	closeButtonText: {
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		color: "white",
	},
	bypass: {
		paddingHorizontal: isMobile ? 75 : isTablet ? 100 : isSmallDesktop ? 125 : 150,
		paddingVertical: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 15 : 15,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
	},
	bypassText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 12: isTablet ? 14 : isSmallDesktop ? 15 : 20,
		fontWeight: "500",
		color: "#fefde2",
		opacity: 0.9,
		lineHeight: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 30,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
	},

	errorText: {
		color: "red",
		fontSize: isMobile ? 8 : isTablet ? 10 : isSmallDesktop ? 12 : 12,
		marginBottom: isMobile ? 5 : isTablet ? 7 : isSmallDesktop ? 10 : 10,
	},
};

export default Popup;
