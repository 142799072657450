import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { View, Text, ImageBackground, StyleSheet } from "react-native";
import { onSnapshot, doc, getFirestore } from "firebase/firestore";
import CookiePolicyPopup from "../Screens/Helpers/CookiePolicyPopup";

import Landing from "../Screens/Landing";
import Profile from "../Screens/Profile";
import Hub from "../Screens/Hub";
import Admin from "../Screens/Admin";
import Events from "../Screens/Events";
import Registry from "../Screens/Registry";
import Catalogue from "../Screens/RegistryWidgets/Catalogue";
import Privacy from "../Screens/Helpers/Privacy";
import Terms from "../Screens/Helpers/Terms";
import Checklist from "../Screens/Helpers/Checklist";
import VerifyEmail from "../Screens/VerifyEmail";

const Stack = createStackNavigator();

const linking = {
	prefixes: ["https://mytassel.com", "mytassel://", "http://localhost:"],
	config: {
		screens: {
			"My Tassel - Welcome": "",
			Hub: "hub",
			Profile: "profile",
			Admin: "admin",
			Catalogue: "catalogue",
			Privacy: "privacy",
			Terms: "terms",
			Checklist: {
				path: "checklist/:type",
				parse: {
					type: (type) => `${type}`,
				},
			},
			Announcements: {
				path: "events/:uid",
				parse: {
					uid: (uid) => `${uid}`,
				},
			},
			Registry: {
				path: "registry/:uid",
				parse: {
					uid: (uid) => `${uid}`,
				},
			},
			VerifyEmail: {
				path: "verify-email",
			},
		},
	},
};

const Navigation = () => {
	const [isMaintenance, setIsMaintenance] = useState(false);
	const [maintenanceStartTime, setMaintenanceStartTime] = useState(null);
	const [currentRoute, setCurrentRoute] = useState("My Tassel - Welcome"); // Track current route

	const db = getFirestore();

	useEffect(() => {
		const statusDocRef = doc(db, "maintenance", "status");
		const unsubscribe = onSnapshot(statusDocRef, (doc) => {
			if (doc.exists()) {
				setIsMaintenance(doc.data().isMaintenanceActive);
				setMaintenanceStartTime(doc.data().startTime);
			}
		});
		return () => unsubscribe();
	}, []);

	return (
		<NavigationContainer linking={linking}>
			{isMaintenance && currentRoute !== "Admin" && ( // Check current route
				<ImageBackground source={require("../assets/Landing Background.png")} style={styles.backgroundImage}>
					<View style={styles.maintenanceMessage}>
						{!maintenanceStartTime && (
							<Text style={styles.maintenanceText}>The site is currently undergoing a scheduled maintenance. Please check back later.</Text>
						)}
						{maintenanceStartTime && (
							<Text style={styles.maintenanceText}>The site is currently undergoing a scheduled maintenance as of {maintenanceStartTime}. Please check back later.</Text>
						)}
					</View>
				</ImageBackground>
			)}
			<Stack.Navigator
				initialRouteName="My Tassel - Welcome"
				screenOptions={({ route }) => {
					setCurrentRoute(route.name); // Update current route
					return { headerShown: false };
				}}
			>
				<Stack.Screen name="My Tassel - Welcome" component={Landing} options={{ headerShown: false }} />
				{!isMaintenance && (
					<>
						<Stack.Screen name="Hub" component={Hub} options={{ headerShown: false }} />
						<Stack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
						<Stack.Screen name="Announcements" component={Events} options={{ headerShown: false }} initialParams={{ uid: "" }} />
						<Stack.Screen name="Registry" component={Registry} options={{ headerShown: false }} initialParams={{ uid: "" }} />
						<Stack.Screen name="Catalogue" component={Catalogue} options={{ headerShown: false }} />
						<Stack.Screen name="Checklist" component={Checklist} options={{ headerShown: false }} initialParams={{ type: undefined }} />
						<Stack.Screen name="Privacy" component={Privacy} options={{ headerShown: false }} />
						<Stack.Screen name="Terms" component={Terms} options={{ headerShown: false }} />
						<Stack.Screen name="VerifyEmail" component={VerifyEmail} options={{ headerShown: false }} />
					</>
				)}
				<Stack.Screen name="Admin" component={Admin} options={{ headerShown: false }} />
			</Stack.Navigator>
			<CookiePolicyPopup />
		</NavigationContainer>
	);
};

const styles = StyleSheet.create({
	backgroundImage: {
		flex: 1,
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
	},
	maintenanceMessage: {
		padding: 20,
		backgroundColor: 'yellow',
	},
	maintenanceText: {
		fontWeight: 'bold',
	},
});

export default Navigation;