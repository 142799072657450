import React, { createContext, useState, useEffect, useContext } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import exampleBackground1 from "../../assets/exBackground1.jpg";
import exampleBackground2 from "../../assets/exBackground2.jpg";
import exampleBackground3 from "../../assets/exBackground3.jpg";
import exampleBackground4 from "../../assets/exBackground4.jpg";
import exampleBackground5 from "../../assets/exBackground5.jpg";
import exampleBackground6 from "../../assets/exBackground6.jpg";
import exampleBackground7 from "../../assets/exBackground7.jpg";

const BackgroundContext = createContext();


export const useBackground = () => useContext(BackgroundContext);

export const BackgroundProvider = ({ children, pageOwner }) => {
    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(() => {
        const fetchBackgroundImage = async () => {
            if (pageOwner && pageOwner.email) {
                const db = getFirestore();
                const userDocRef = doc(db, "users", pageOwner.email);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setBackgroundImage(data.gradCardBackgroundImage || exampleBackground2);
                }
            }
        };

        fetchBackgroundImage();
    }, [pageOwner]);

    return (
        <BackgroundContext.Provider value={{ backgroundImage, setBackgroundImage }}>
            {children}
        </BackgroundContext.Provider>
    );
};