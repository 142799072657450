import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import { Animated, Text, View, TextInput, ScrollView, ImageBackground, Modal, Button, Pressable, Image, TouchableOpacity, Alert, Dimensions, ActivityIndicator } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updateEmail, updatePassword, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { firebaseConfig, app } from "../firebase";
import styles from "../Styles/ProfileStyles";
import { getFirestore, doc, setDoc, updateDoc, getDoc, getDocs, collection, query, where } from "firebase/firestore";
import { useUser } from "../Widgets/UserContext";
import DropDownPicker from "react-native-dropdown-picker";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage
import HeaderBar from "../Widgets/HeaderBar";
import { useFocusEffect } from "@react-navigation/native";
import genericUserPhoto from "../assets/generic.jpg";
import organizationsData from '../organizations/MyTassel_Organziations.json'; // Import the local JSON file

// Initialize Firestore and other Firebase services
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const Profile = () => {
	//#region - Variable
	const { currentUserData, setCurrentUserData } = useUser();
	console.log(currentUserData);
	const auth = getAuth(app);
	let updatedUserData;

	// Initialize state for currentUserData fields
	// States for handling password visibility and user's name

	const [firstName, setFirstName] = React.useState(currentUserData ? currentUserData.firstName : "");
	const [lastName, setLastName] = React.useState(currentUserData ? currentUserData.lastName : "");
	const [hasConfirmedEmail, setHasConfirmedEmail] = React.useState(currentUserData ? currentUserData?.hasConfirmedEmail : false);

	const [email, setEmail] = React.useState(currentUserData ? currentUserData.email : "");
	const [newEmail, setNewEmail] = React.useState(currentUserData ? currentUserData.email : "");
	const [newPassword, setNewPassword] = React.useState(""); // State to store new password
	const [passwordVisible, setPasswordVisible] = React.useState(true);
	const [organizationName, setOrganizationName] = React.useState(currentUserData && currentUserData.organization ? currentUserData.organization.name : "");
	const [organizationCode, setOrganizationCode] = React.useState(currentUserData ? currentUserData.organization.code : "");

	const [graduationMonth, setGraduationMonth] = useState(currentUserData ? currentUserData.graduationMonth : "");
	const [graduationDay, setGraduationDay] = useState(currentUserData ? currentUserData.graduationDay : "");
	const [graduationYear, setGraduationYear] = useState(currentUserData ? currentUserData.graduationYear : (new Date().getFullYear()).toString());

	const [openGradMonth, setOpenGradMonth] = useState(false);
	const [openGradDay, setOpenGradDay] = useState(false);
	const [openGradYear, setOpenGradYear] = useState(false);
	const [valueGradMonth, setValueGradMonth] = useState("");

	const [pendingAccountType, setPendingAccountType] = useState(null);
	const [pendingUID, setPendingUID] = useState(null);
	const [newDegree, setNewDegree] = React.useState(currentUserData ? currentUserData.degree : "");

	const [isAccountTypeModalVisible, setIsAccountTypeModalVisible] = useState(false);
	const [accountTypes, setAccountTypes] = useState([
		{ label: "Graduate", value: "G" },
		{ label: "Friends & Family", value: "F" },
		// Add other account types as needed
	]);
	const [accountTypeChanged, setAccountTypeChanged] = useState(false);
	// Dropdown for account type
	const [accountTypeOpen, setAccountTypeOpen] = useState(false);
	const [accountTypeValue, setAccountTypeValue] = useState(currentUserData?.accountType || null);
	const accountTypeItems = [
		{ label: "Graduate", value: "G" },
		{ label: "Friends & Family", value: "F" },
		// Add other account types as needed
	];
	// Additional state variables
	const [organizationType, setOrganizationType] = useState("Select Organization Type");
	const [customOrgName, setCustomOrgName] = useState("");
	const [orgAddress, setOrgAddress] = useState("");
	const [orgWebsite, setOrgWebsite] = useState("");

	const [institutionCode, setInstitutionCode] = useState("");

	// Dropdown Items
	const [openDropDown, setOpenDropDown] = useState(false);
	const [orgTypeItems, setOrgTypeItems] = useState([
		{ label: "University or College", value: "University or College" },
		{ label: "High School", value: "High School" },
		{ label: "Vocational School", value: "Vocational School" },
		{ label: "Other", value: "Other" },
	]);

	const [universities, setUniversities] = useState([]); // State to store the list of universities
	const [filteredUniversities, setFilteredUniversities] = useState([]); // State for the filtered list for the dropdown
	const [universitiesCache, setUniversitiesCache] = useState({}); // Cache for universities
	const [searchTerm, setSearchTerm] = useState(""); // State to store the search term

	const [showSavePopup, setShowSavePopup] = useState(false);
	const savePopupAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity:0

	const [showAddOrgSuccess, setShowAddOrgSuccess] = useState(false);
	const addOrgSuccessAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

	const [isOrgTypeModalVisible, setIsOrgTypeModalVisible] = useState(false);
	const [selectedOrgType, setSelectedOrgType] = useState("");

	const [showAddButton, setShowAddButton] = useState(false);

	const fileInputRef = useRef(null);

	const states = [
		{ label: "Alabama", value: "AL" },
		{ label: "Alaska", value: "AK" },
		{ label: "Arizona", value: "AZ" },
		{ label: "Arkansas", value: "AR" },
		{ label: "California", value: "CA" },
		{ label: "Colorado", value: "CO" },
		{ label: "Connecticut", value: "CT" },
		{ label: "Delaware", value: "DE" },
		{ label: "Florida", value: "FL" },
		{ label: "Georgia", value: "GA" },
		{ label: "Hawaii", value: "HI" },
		{ label: "Idaho", value: "ID" },
		{ label: "Illinois", value: "IL" },
		{ label: "Indiana", value: "IN" },
		{ label: "Iowa", value: "IA" },
		{ label: "Kansas", value: "KS" },
		{ label: "Kentucky", value: "KY" },
		{ label: "Louisiana", value: "LA" },
		{ label: "Maine", value: "ME" },
		{ label: "Maryland", value: "MD" },
		{ label: "Massachusetts", value: "MA" },
		{ label: "Michigan", value: "MI" },
		{ label: "Minnesota", value: "MN" },
		{ label: "Mississippi", value: "MS" },
		{ label: "Missouri", value: "MO" },
		{ label: "Montana", value: "MT" },
		{ label: "Nebraska", value: "NE" },
		{ label: "Nevada", value: "NV" },
		{ label: "New Hampshire", value: "NH" },
		{ label: "New Jersey", value: "NJ" },
		{ label: "New Mexico", value: "NM" },
		{ label: "New York", value: "NY" },
		{ label: "North Carolina", value: "NC" },
		{ label: "North Dakota", value: "ND" },
		{ label: "Ohio", value: "OH" },
		{ label: "Oklahoma", value: "OK" },
		{ label: "Oregon", value: "OR" },
		{ label: "Pennsylvania", value: "PA" },
		{ label: "Rhode Island", value: "RI" },
		{ label: "South Carolina", value: "SC" },
		{ label: "South Dakota", value: "SD" },
		{ label: "Tennessee", value: "TN" },
		{ label: "Texas", value: "TX" },
		{ label: "Utah", value: "UT" },
		{ label: "Vermont", value: "VT" },
		{ label: "Virginia", value: "VA" },
		{ label: "Washington", value: "WA" },
		{ label: "West Virginia", value: "WV" },
		{ label: "Wisconsin", value: "WI" },
		{ label: "Wyoming", value: "WY" },
	];

	const months = [
		{ label: "January", value: "January", key: "January" },
		{ label: "February", value: "February", key: "February" },
		{ label: "March", value: "March", key: "March" },
		{ label: "April", value: "April", key: "April" },
		{ label: "May", value: "May", key: "May" },
		{ label: "June", value: "June", key: "June" },
		{ label: "July", value: "July", key: "July" },
		{ label: "August", value: "August", key: "August" },
		{ label: "September", value: "September", key: "September" },
		{ label: "October", value: "October", key: "October" },
		{ label: "November", value: "November", key: "November" },
		{ label: "December", value: "December", key: "December" },
	];


	const days = Array.from({ length: 31 }, (_, i) => {
		const day = i + 1;
		return { label: `${day}`, value: day, key: `day-${day}` };
	});

	// Existing years array
	const years = ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040"];

	// Transforming the years array into an array of objects with unique keys
	const yearsWithKeys = years.map((year) => ({ label: year, value: year, key: year }));

	// State variables for shipping address
	const [shippingAddress, setShippingAddress] = useState(currentUserData ? currentUserData.shippingAddress : "");
	const [shippingCity, setShippingCity] = useState(currentUserData ? currentUserData.shippingCity : "");
	const [selectedState, setSelectedState] = useState(() => {
		// Check if currentUserData.shippingState exists in the states array
		const foundState = states.find(state => state.value === currentUserData?.shippingState);

		return foundState ? foundState.label : "Select State"; // Set the label if found, otherwise set to "Select State"
	});
	const [shippingState, setShippingState] = useState(currentUserData ? currentUserData.shippingState : "");
	const [shippingZip, setShippingZip] = useState(currentUserData ? currentUserData.shippingZip : "");

	const [openStateDropdown, setOpenStateDropdown] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(null); // State to track the currently open dropdown

	// Function to handle state selection
	const handleStateSelect = (state) => {
		setSelectedState(state?.label); // Set the selected state label
		setShippingState(state?.value); // Update shippingState when a state is selected
	};

	const handleDropdownOpen = (dropdownName) => {
		setOpenDropdown(dropdownName);
		if (dropdownName !== 'university') {
			setShowAddButton(false);
			setFilteredUniversities([]);
		}
	};

	const handleDropdownClose = () => {
		setOpenDropdown(null);
		setTimeout(() => {
			// Refocus the university input after a short delay
			if (fileInputRef.current) {
				fileInputRef.current.focus();
			}
		}, 100); // Adjust the delay as needed
	};

	const handleUniversitySelect = (selectedUniversity) => {
		setOrganizationName(selectedUniversity.name);
		setOrganizationCode(selectedUniversity.organizationCode || selectedUniversity.organizationCode); // Set the corresponding institution code
		setFilteredUniversities([]);
		setShowAddButton(false);
	};
	//#endregion

	//#region - Add Organization Functions
	const handleOrganizationInputChange = (input) => {
		setOrganizationName(input); // Update the organization name as the currentUserData types
		setCustomOrgName(input); // Set the custom organization name

		// Update the filtered universities based on the input
		if (input.length > 2) {
			const matchingUniversities = Object.values(universitiesCache).filter((uni) => uni.name.toLowerCase().includes(input.toLowerCase()));
			setFilteredUniversities(matchingUniversities);
		} else {
			setFilteredUniversities([]);
		}

		// Determine if the "Add" button should be shown
		const addButtonVisible = customOrgName && !universities.some((uni) => uni.name.toLowerCase() === customOrgName.toLowerCase());
		setShowAddButton(addButtonVisible);
	};

	const shouldShowAddButton = () => {
		// Check if customOrgName is not in the list of existing organizations
		return customOrgName && !universities.some((uni) => uni.name.toLowerCase() === customOrgName.toLowerCase());
	};

	const toggleOrgTypeModal = () => {
		setCustomOrgName(organizationName); // Autofill the organization name in the modal
		setIsOrgTypeModalVisible(!isOrgTypeModalVisible);
		setOpenDropDown(false);
	};

	// Function to generate institution code
	const generateInstitutionCode = (selectedType) => {
		console.log(selectedType);
		console.log(organizationType);
		const prefix = selectedType === "University or College" ? "U" : selectedType === "High School" ? "H" : "I";
		const randomNumber = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
		return `${prefix}${randomNumber}`;
	};

	// Update this function to handle organization type change
	const onOrgTypeChange = (selectedType) => {
		setOrganizationType(selectedType);
		setOrganizationType(selectedType);
		const code = generateInstitutionCode(selectedType);
		setInstitutionCode(code);
	};

	const submitCustomOrganization = async () => {
		if (!customOrgName || !orgAddress || !orgWebsite || !organizationType) {
			alert("Please fill in all fields.");
			return;
		}

		const organizationData = {
			name: customOrgName,
			location: orgAddress,
			website: orgWebsite,
			organizationType: organizationType,
			code: institutionCode,
		};

		try {
			await setDoc(doc(db, "new organizations", customOrgName), organizationData);

			// Set the organization name in the main form state
			setOrganizationName(customOrgName); // Populate the main form field
			setOrganizationCode(institutionCode); // Optionally set the code as well

			setShowAddOrgSuccess(true); // Show success message
			animateAddOrgSuccessPopup(); // Trigger the animation
			setShowAddButton(false);
			toggleOrgTypeModal(); // Close the modal
		} catch (error) {
			console.error("Error adding custom organization:", error);
		}
	};
	//#endregion

	//#region - Profile Picture Functions
	const [isUploading, setIsUploading] = useState(false); // State for loading indicator

	const updateUserProfilePicture = async (url) => {
		try {
			// Assuming email is the unique identifier for the currentUserData
			await updateDoc(doc(db, "users", currentUserData.email), {
				profilePictureURL: url,
			});
			// Update the currentUserData context or state as needed
			setCurrentUserData({ ...currentUserData, profilePictureURL: url });
			// alert("Success", "Profile picture updated successfully.");
		} catch (error) {
			console.error("Error updating profile picture:", error);
			alert("Error", "Failed to update profile picture.");
		} finally {
			setIsUploading(false); // Stop loading indicator
		}
	};

	const handleImageSelect = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				// Optional: You can display the selected image using e.target.result
			};
			reader.readAsDataURL(file);
			uploadImageToStorage(file);
		}
	};

	const uploadImageToStorage = (file) => {
		setIsUploading(true); // Start loading indicator
		const uid = auth.currentUser.uid;
		const filename = `${uid}_${new Date().getTime()}.jpg`; // Example: UID_timestamp.jpg
		const storageRef = ref(storage, `profilePictures/${filename}`);

		uploadBytes(storageRef, file)
			.then((snapshot) => {
				getDownloadURL(snapshot.ref)
					.then((downloadURL) => {
						updateUserProfilePicture(downloadURL);
					})
					.catch((error) => {
						console.error("Error getting download URL:", error);
						setIsUploading(false); // Stop loading indicator on error
						alert("Error", "Failed to get download URL.");
					});
			})
			.catch((error) => {
				alert("Error uploading image: ", error);
				setIsUploading(false); // Stop loading indicator on error
				alert("Error", "Failed to upload image.");
			});
	};

	const handleClickChangePicture = () => {
		fileInputRef.current.click();
	};
	//#endregion

	//#region - Other Profile Functions
	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const handlePasswordReset = async () => {
		if (!email) {
			alert("Please enter your email address.");
			return;
		}

		try {
			await sendPasswordResetEmail(auth, email);
			alert("A link to reset your password has been sent to your email address.");
		} catch (error) {
			alert("Reset Password Error", error.message);
		}
	};

	const updateAuthEmailAndPassword = async (currentUser, newEmail, newPassword) => {
		try {
			if (currentUser.email !== newEmail) {
				await updateEmail(currentUser, newEmail);
			}
			if (newPassword) {
				await updatePassword(currentUser, newPassword);
			}
		} catch (error) {
			console.error("Error updating authentication details:", error);
		}
	};
	// Function to generate new unique UID
	const generateNewUID = async (typePrefix, numericPart) => {
		let potentialUID = `${typePrefix}${numericPart}`;
		const docRef = doc(db, "users", potentialUID);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			// If UID exists, generate a new numericPart or handle it as needed
		} else {
			return potentialUID;
		}
	};

	// Function to handle account type selection
	const onAccountTypeChange = async (value) => {
		console.warn("Account Changed");
		setAccountTypeValue(value); // Update the dropdown display value

		// Set account type change as pending
		setAccountTypeChanged(true);

		// Find the full label for the selected account type
		const selectedTypeLabel = accountTypeItems.find((item) => item.value === value)?.label;
		if (selectedTypeLabel) {
			setPendingAccountType(selectedTypeLabel); // Set the full label
		}

		if (value === "G") {
			setIsAccountTypeModalVisible(true); // Show modal for graduation year input
			const numericPart = currentUserData.uid.substring(1);
			const newUID = `G${numericPart}`;
			setPendingUID(newUID);
		} else if (value === "F") {
			setIsAccountTypeModalVisible(false); // Hide modal
			const numericPart = currentUserData.uid.substring(1);
			const newUID = `${value.charAt(0)}${numericPart}`;
			setPendingUID(newUID);
		} else {
			setIsAccountTypeModalVisible(false); // Hide modal
		}
	};

	// Add a function to check UID availability
	const checkUIDAvailability = async (numericPart, typePrefix) => {
		let newUID = `${typePrefix}${numericPart}`;

		console.log("newUid:", newUID);
		const querySnapshot = await getDocs(query(collection(db, "users"), where("uid", "==", newUID)));
		return querySnapshot.empty; // Return true if UID is available
	};

	// Updated function to handle account type change
	const updateGraduationDate = async (year, month, day) => {
		try {
			let graduationDate;

			// Combine year, month, and day into a date string
			if (month && day && year) {
				graduationDate = `${month}, ${day} ${year}`;
			} else if (month && year) {
				graduationDate = `${month} ${year}`;
			} else if (month) {
				graduationDate = `${month}`;
			} else if (year) {
				graduationDate = `${year}`;
			}

			// Update Firestore
			await updateDoc(doc(db, "users", currentUserData.email), {
				graduationDate, // Add this to store the complete graduation date
			});

			// Update currentUserData context
			setCurrentUserData({
				...currentUserData,
				graduationDate,
			});
		} catch (error) {
			console.error("Error updating account type:", error);
		}
	};

	// Function to animate the popup
	const animatePopup = () => {
		// Fade in animation
		Animated.timing(savePopupAnim, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start();

		// Fade out after 3 seconds
		setTimeout(() => {
			Animated.timing(savePopupAnim, {
				toValue: 0,
				duration: 500,
				useNativeDriver: true,
			}).start();
		}, 3000);
	};

	const animateAddOrgSuccessPopup = () => {
		// Fade in animation
		Animated.timing(addOrgSuccessAnim, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start();

		// Fade out after 3 seconds
		setTimeout(() => {
			Animated.timing(addOrgSuccessAnim, {
				toValue: 0,
				duration: 500,
				useNativeDriver: true,
			}).start();
		}, 3000);
	};

	// Call this function when the save operation is successful
	const handleSaveSuccess = () => {
		setShowSavePopup(true);
		animatePopup();
	};

	const handleSave = async () => {
		if (!currentUserData || !currentUserData.uid) {
			alert("Cannot save profile at this time. Please try again later.");
			console.error("User data is not loaded properly.");
			return;
		}

		// Check if address fields are filled for graduates
		if (accountTypeValue === "Graduate" && (!shippingAddress || !shippingCity || !shippingState || !shippingZip)) {
			console.log(shippingAddress, shippingCity, shippingState, shippingZip);
			alert("Please fill in all address fields.");
			return;
		}

		// Check if the organization name is valid
		if (accountTypeValue === "Graduate" && organizationName && !organizationCode) {
			alert("Please select or add an organization.");
			return;
		}

		// Formatting the graduation date as "Month Day, Year"
		const formattedGraduationDate = (() => {
			const hasMonth = graduationMonth !== "";
			const hasDay = graduationDay !== "";
			const hasYear = graduationYear !== "";

			if (hasMonth && hasDay && hasYear) {
				return `${graduationMonth} ${graduationDay}, ${graduationYear}`;
			} else if (hasMonth && hasYear) {
				return `${graduationMonth} ${graduationYear}`;
			} else if (hasMonth) {
				return `${graduationMonth}`;
			} else if (hasYear) {
				return `${graduationYear}`;
			} else {
				return currentUserData.graduationDate || "Date not set";
			}
		})();

		try {
			// Check if the new email already exists in the database
			if (newEmail && newEmail !== currentUserData.email) {
				const emailQuery = query(collection(db, "users"), where("email", "==", newEmail));
				const querySnapshot = await getDocs(emailQuery);

				if (!querySnapshot.empty) {
					alert("The new email address is already in use. Please choose a different email.");
					return;
				}
			}

			// Prepare the updated user data object
			updatedUserData = {
				...currentUserData,
				firstName,
				lastName,
				email: currentUserData.email,
				newEmail: newEmail || currentUserData?.newEmail || "",
				hasConfirmedEmail: false,
				organization: {
					name: organizationName || "",
					code: organizationCode || "",
				},
				degree: newDegree || currentUserData.degree || "",
				graduationDate: formattedGraduationDate,
				graduationMonth,
				graduationDay,
				graduationYear,
				shippingAddress,
				shippingCity,
				shippingState: shippingState || "", // Ensure shippingState is defined
				shippingZip,
			};

			const currentUser = auth.currentUser;
			if (newEmail && newEmail !== currentUserData.email) {
				// Send verification email
				const actionCodeSettings = {
					url: 'https://mytassel.com/verify-email?uid=' + currentUser.uid,
					handleCodeInApp: true
				};
				await sendEmailVerification(currentUser, actionCodeSettings);

				// Update the email in the database but do not change the document name
				await setDoc(doc(db, "users", currentUserData.email), updatedUserData);

				alert("A verification email has been sent to your new email address. Please verify it to complete the email change.");
			} else {
				await setDoc(doc(db, "users", currentUserData.email), updatedUserData);
			}

			if (newPassword) {
				await updatePassword(currentUser, newPassword);
			}

			if (pendingAccountType && pendingUID) {
				const isUIDAvailable = await checkUIDAvailability(pendingUID.substring(1), pendingAccountType);
				if (isUIDAvailable) {
					updatedUserData.uid = pendingUID;
					updatedUserData.accountType = pendingAccountType;

					// Update page URLs to match the new UID
					const eventsPageUrl = `https://mytassel.com/events/${pendingUID}`;
					const registryPageUrl = `https://mytassel.com/registry/${pendingUID}`;

					await updateDoc(doc(db, "users", currentUserData.email), {
						"pages.events": eventsPageUrl,
						"pages.registry": registryPageUrl,
					});

					updatedUserData.pages = {
						...updatedUserData.pages,
						events: eventsPageUrl,
						registry: registryPageUrl,
					};

					setPendingAccountType(null);
					setPendingUID(null);
				}
			}

			setCurrentUserData(updatedUserData);
			setAccountTypeChanged(false); // Reset the account type change flag
			handleSaveSuccess();
		} catch (error) {
			console.error("Error updating profile:", error);
		}
	};

	// Function to handle account type change button press
	const handleAccountTypeChangePress = () => {
		alert("You have changed the account type. Please press the \"Save\" button to finalize the update.");
		handleSave();
	};

	// Function to handle university name input change
	const handleUniversityInputChange = (input) => {
		setOrganizationName(input);
		setSearchTerm(input);
		setShowAddButton(true);

		// Combine local universities and approved organizations for filtering
		const combinedOrganizations = [...universities, ...approvedOrganizations];
		const matchingOrgs = combinedOrganizations.filter((org) => org.name.toLowerCase().includes(input.toLowerCase()));
		setFilteredUniversities(matchingOrgs);
	};

	// Debounced search function
	const debouncedSearch = useCallback(
		debounce((input) => {
			if (input.length > 2) {
				const matchingUniversities = Object.values(universitiesCache).filter((uni) => uni.name.toLowerCase().includes(input.toLowerCase()));
				setFilteredUniversities(matchingUniversities);
			} else {
				setFilteredUniversities([]);
			}
		}, 1000), // 1 second debounce
		[universitiesCache]
	);

	// Effect to trigger the debounced search
	useEffect(() => {
		if (searchTerm) {
			debouncedSearch(searchTerm);
		}
	}, [searchTerm, debouncedSearch]);

	// State for approved organizations
	const [approvedOrganizations, setApprovedOrganizations] = useState([]);

	// Function to fetch approved organizations from Firestore
	const fetchApprovedOrganizations = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, "approvedOrganizations"));
			const orgs = querySnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data().members // Assuming members contains the organization details
			}));
			setApprovedOrganizations(orgs);
		} catch (error) {
			console.error("Error fetching approved organizations:", error);
		}
	};

	// Call fetchApprovedOrganizations on component mount
	useEffect(() => {
		fetchApprovedOrganizations();
	}, []);

	//#endregion

	//#region - Initialization
	useFocusEffect(
		useCallback(() => {
			if (currentUserData && currentUserData.accountType) {
				setAccountTypeValue(currentUserData.accountType);
				console.log(accountTypeValue);
			}
		}, [])
	);

	// Function to fetch universities from S3 and cache them
	const fetchUniversities = async () => {
		try {
			// Load the local JSON data
			setUniversities(organizationsData);
			setUniversitiesCache(organizationsData.reduce((acc, uni) => {
				acc[uni.name.toLowerCase()] = uni;
				return acc;
			}, {}));
		} catch (error) {
			console.error("Error fetching universities:", error);
		}
	};

	useEffect(() => {
		if (!universities.length) {
			fetchUniversities();
		}
	}, [universities]);

	useEffect(() => {
		if (currentUserData && currentUserData.accountType) {
			setAccountTypeValue(currentUserData.accountType);
			console.log(accountTypeValue);
		}

		// Update local state variables based on currentUserData
		setEmail(currentUserData?.email || "");
		setFirstName(currentUserData?.firstName || "");
		setLastName(currentUserData?.lastName || "");
		setNewEmail(currentUserData?.newEmail || "");
		setOrganizationName(currentUserData?.organization?.name || "");
		setGraduationYear(currentUserData?.graduationYear || new Date().getFullYear());
		setGraduationMonth(currentUserData?.graduationMonth || "");
		setGraduationDay(currentUserData?.graduationDay || "");
		// Add any other fields that need to be updated based on currentUserData
		setShippingAddress(currentUserData?.shippingAddress || "");
		setShippingCity(currentUserData?.shippingCity || "");
		setShippingZip(currentUserData?.shippingZip || "");

		const foundState = states.find(state => state.value === currentUserData?.shippingState);
		console.log("foundState", foundState, "Current User Data Shipping State", currentUserData?.shippingState)
		setSelectedState(foundState ? foundState.label : "Select State"); // Set the selected state label
		setShippingState(foundState ? foundState.value : ""); // Ensure shippingState is also set to the value
		handleStateSelect(foundState ? foundState : "");
		console.log("selectedState", selectedState, "Shipping State", shippingState)
	}, [currentUserData]); // Depend on currentUserData to re-run this effect

	//#endregion

	return (
		<ScrollView style={styles.body}>
			<ImageBackground source={require("../assets/Hub Background.png")} style={styles.backgroundImage}>
				<div style={{ ...styles.overlayBlur }} />
				{/* Header Bar */}
				<HeaderBar />
				{/* Profile Image & Details */}
				<View style={styles.profileContainer}>
					<Text style={styles.welcomeText}>My Profile</Text>

					<Pressable onPress={handleClickChangePicture} style={styles.profileImageContainer}>
						{isUploading ? (
							<ActivityIndicator size="large" color="green" style={styles.activityIndicator} /> // Loading spinner
						) : (
							<Image
								style={styles.profileImage}
								source={currentUserData && currentUserData.profilePictureURL ? { uri: currentUserData.profilePictureURL } : genericUserPhoto}
							/>
						)}
						<input type="file" ref={fileInputRef} onChange={handleImageSelect} style={{ display: "none" }} accept="image/*" />
					</Pressable>

					<Image style={styles.sealImage} source={require("../assets/Seal.png")} />
					<Text style={styles.profileName}>{currentUserData ? currentUserData.firstName + " " + currentUserData.lastName : ""}</Text>
					<Text style={styles.profileDetails}>{currentUserData ? currentUserData.organization.name : ""}</Text>
					<Text style={styles.profileDetails}>{currentUserData && currentUserData.accountType.toLowerCase() === "graduate" && currentUserData.graduationDate ? `Graduation Date: ${currentUserData.graduationDate}` : "Graduation Date: "}</Text>
				</View>

				{/* Form Fields */}
				<View style={styles.inputContainer}>
					<View style={styles.instructionsContainer}>
						<Text style={styles.instructions}>Edit Your Profile Information</Text>
					</View>

					<View style={styles.formContainer}>
						<View style={styles.forms}>
							<Text style={styles.inputText}>First Name *</Text>
							<TextInput
								style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
								value={firstName}
								onChangeText={setFirstName}
								placeholder={currentUserData ? currentUserData.firstName : ""}
								placeholderTextColor="#2c6e49"
							/>
						</View>

						<View style={styles.forms}>
							<Text style={styles.inputText}>Last Name *</Text>
							<TextInput
								style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
								value={lastName}
								onChangeText={setLastName}
								placeholder={currentUserData ? currentUserData.lastName : ""}
								placeholderTextColor="#2c6e49"
							/>
						</View>
					</View>

					<View style={styles.formContainer}>
						<View style={styles.forms}>
							<Text style={styles.inputText}>Email *</Text>
							<TextInput
								style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
								value={newEmail}
								onChangeText={setNewEmail}
								placeholder={currentUserData ? currentUserData.email : ""}
								placeholderTextColor="#2c6e49"
							/>
						</View>

						{/* // Password field with visibility toggle */}
						<View style={styles.forms}>
							<Text style={styles.inputText}>Password *</Text>
							<TextInput style={styles.inputField} placeholder="*********" onChangeText={setNewPassword} secureTextEntry={passwordVisible} placeholderTextColor="#2c6e49" />

							{/* // Reset Password Button */}
							<View style={styles.formContainer}>
								<TouchableOpacity onPress={togglePasswordVisibility}>{/* <Text style={styles.viewPasswordText}>{passwordVisible ? "Show" : "Hide"}</Text> */}</TouchableOpacity>
								<TouchableOpacity onPress={handlePasswordReset}>
									<Text style={styles.resetPasswordButtonText}>Reset Password Via Email</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>

					<View style={[styles.formContainer, { zIndex: openDropdown === 'university' ? 10000 : 9999 }]}>
						{accountTypeValue == "Graduate" && (
							<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 10000 : 9999 }}>
								<Text style={styles.inputText}>{isMobile ? "University" : "University or Organization"}</Text>
								<TextInput
									style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
									placeholder={currentUserData ? currentUserData.organization.name : ""}
									value={organizationName}
									onChangeText={handleUniversityInputChange}
									placeholderTextColor="#2c6e49"
									onFocus={() => handleDropdownOpen('university')}
									onBlur={() => {
										// Delay closing the dropdown to allow click on dropdown items
										setTimeout(() => handleDropdownClose(), 500); // Adjust the timeout duration as needed
									}}
								/>

								{showAddButton && (
									<TouchableOpacity onPress={toggleOrgTypeModal}>
										<Text style={styles.addButton}>Add New Organization</Text>
									</TouchableOpacity>
								)}

								{/* Dropdown for university selection */}
								{filteredUniversities.length > 0 && (
									<View style={[styles.dropdown, { zIndex: 10001 }]}>
										{filteredUniversities.map((uni) => (
											<TouchableOpacity
												key={uni.name}
												onPress={() => {
													// Use a timeout to allow the selection to register
													setTimeout(() => {
														handleUniversitySelect(uni); // Select the university
														setOpenDropdown(null); // Close the dropdown
													}, 100); // Adjust the timeout duration as needed
												}}
												style={styles.dropdownItem}
											>
												<Text style={styles.dropdownText}>{uni.name}</Text>
											</TouchableOpacity>
										))}
									</View>
								)}
							</View>
						)}

						<View style={styles.forms}>
							<Text style={styles.inputText}>Account Type *</Text>
							<DropDownPicker
								style={{ ...styles.inputField }}
								open={accountTypeOpen}
								value={accountTypeValue}
								placeholder={accountTypeValue}
								placeholderStyle={{ fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
								items={[
									// Conditional placeholder item
									...(accountTypeValue === null ? [{ label: "Select Account Type", value: null, untouchable: true }] : []),
									// Your account types
									{ label: "Graduate", value: "G" },
									{ label: "Friends & Family", value: "F" },
								]}
								setOpen={setAccountTypeOpen}
								setValue={(callback) => {
									const newValue = callback(accountTypeValue);
									setAccountTypeValue(newValue);
									onAccountTypeChange(newValue);
								}}
								textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps }}
								dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
								dropDownDirection="TOP"
							/>
						</View>
					</View>

					{accountTypeValue == "Graduate" && (
						<View style={{ ...styles.formContainer, zIndex: 9999 }}>
							<View style={styles.forms}>
								<Text style={{ ...styles.inputText }}>{isMobile ? "Degree or Certificate" : "Degree or Certificate Title (If Applicable)"}</Text>
								<TextInput style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }} value={newDegree} onChangeText={setNewDegree} placeholder={currentUserData ? currentUserData.degree : ""} placeholderTextColor="#2c6e49" />
							</View>

							<View style={styles.forms}>
								<Text style={styles.inputText}>Institution Code</Text>
								<TextInput style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }} placeholder={currentUserData ? currentUserData.organization.code : ""} placeholderTextColor="#2c6e49" editable={false} />
							</View>
						</View>
					)}

					{/* Graduation Date Fields - Displayed only for 'Graduate' account type */}
					{accountTypeValue == "Graduate" && (
						<View style={{ ...styles.formContainer, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
							<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
								<Text style={styles.inputText}>Graduation Month</Text>
								<DropDownPicker
									open={openGradMonth}
									value={graduationMonth}
									placeholder="Select Graduation Month"
									placeholderStyle={{ fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
									items={[{ label: "None", value: "" }, ...months]}
									setOpen={(open) => {
										setOpenGradMonth(open);
										open ? handleDropdownOpen('gradMonth') : handleDropdownClose();
									}}
									setValue={setGraduationMonth}
									dropDownDirection="TOP"
									style={{ ...styles.inputField, zIndex: 2000 }} // Apply your styles here
									dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
									textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
								/>
							</View>

							<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
								<Text style={styles.inputText}>Graduation Day</Text>
								<DropDownPicker
									open={openGradDay}
									value={graduationDay}
									items={[{ label: "None", value: "" }, ...days]}
									setOpen={(open) => {
										setOpenGradDay(open);
										open ? handleDropdownOpen('gradDay') : handleDropdownClose();
									}}
									setValue={setGraduationDay}
									dropDownDirection="TOP"
									placeholder="Select Graduation Day"
									placeholderStyle={{ fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
									style={{ ...styles.inputField, zIndex: 2000 }}
									textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
									dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
								/>
							</View>
							<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
								<Text style={styles.inputText}>Graduation Year</Text>
								<DropDownPicker
									open={openGradYear}
									value={graduationYear}
									items={[{ label: "None", value: "" }, ...yearsWithKeys]}
									setOpen={(open) => {
										setOpenGradYear(open);
										open ? handleDropdownOpen('gradYear') : handleDropdownClose();
									}}
									setValue={setGraduationYear}
									dropDownDirection="TOP"
									style={{ ...styles.inputField, zIndex: 2000 }} // Apply your styles here
									textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
									dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
								/>
							</View>
						</View>
					)}

					{accountTypeValue == "Graduate" && (
						<View style={{ ...styles.formContainer, zIndex: 10001 }}>
							<View style={styles.forms}>
								<Text style={styles.inputText}>Shipping Address *</Text>
								<TextInput
									style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
									value={shippingAddress}
									onChangeText={setShippingAddress}
									placeholder="1234 Main St"
									placeholderTextColor="#2c6e49"
									placeholderStyle={{ fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
								/>
							</View>
							<View style={styles.forms}>
								<Text style={styles.inputText}>City *</Text>
								<TextInput
									style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
									value={shippingCity}
									onChangeText={(text) => {
										if (/^[a-zA-Z\s]*$/.test(text)) { // Allows letters and spaces
											setShippingCity(text);
										}
									}}
									placeholder="Anytown"
									placeholderTextColor="#2c6e49"
								/>
							</View>

							{!isMobile && (
								<>
									<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
										<Text style={styles.inputText}>State *</Text>
										<DropDownPicker
											open={openStateDropdown}
											value={shippingState}
											items={states}
											setOpen={(open) => {
												setOpenStateDropdown(open);
												open ? handleDropdownOpen('state') : handleDropdownClose();
											}}
											setValue={setShippingState} // Directly set the state value
											onChangeValue={(value) => {
												const selected = states.find(state => state.value === value);
												handleStateSelect(selected); // Use the new handler
											}}
											style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
											textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
											dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
											dropDownDirection="TOP" // Make the dropdown expand upwards
											placeholder={states.find(state => state.value === currentUserData?.shippingState)?.label || isMobile ? "Select State" : "Select an item"}
										/>
									</View>


									<View style={styles.forms}>
										<Text style={styles.inputText}>Zip *</Text>
										<TextInput
											style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
											value={shippingZip}
											onChangeText={(text) => {
												if (/^\d{0,5}$|^\d{5}-\d{0,4}$/.test(text)) {
													setShippingZip(text);
												}
											}}
											placeholder="12345 or 12345-6789"
											placeholderTextColor="#2c6e49"
											maxLength={10}
										/>
									</View>
								</>
							)}
						</View>
					)}

					{isMobile && (
						<View style={styles.formContainer}>
							<View style={{ ...styles.forms, zIndex: openDropdown === 'university' ? 9999 : 10000 }}>
								<Text style={styles.inputText}>State *</Text>
								<DropDownPicker
									open={openStateDropdown}
									value={shippingState}
									items={states}
									setOpen={(open) => {
										setOpenStateDropdown(open);
										open ? handleDropdownOpen('state') : handleDropdownClose();
									}}
									setValue={setShippingState} // Directly set the state value
									onChangeValue={(value) => {
										const selected = states.find(state => state.value === value);
										handleStateSelect(selected); // Use the new handler
									}}
									style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
									textStyle={{ color: "#2c6e49", fontFamily: fontFamilies.Bodoni72Smallcaps }}
									dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
									dropDownDirection="TOP" // Make the dropdown expand upwards
									placeholder={states.find(state => state.value === currentUserData?.shippingState)?.label || isMobile ? "Select State" : "Select an item"}
								/>
							</View>


							<View style={styles.forms}>
								<Text style={styles.inputText}>Zip *</Text>
								<TextInput
									style={{ ...styles.inputField, fontFamily: fontFamilies.Bodoni72Smallcaps }}
									value={shippingZip}
									onChangeText={(text) => {
										if (/^\d{0,5}$|^\d{5}-\d{0,4}$/.test(text)) {
											setShippingZip(text);
										}
									}}
									placeholder="12345 or 12345-6789"
									placeholderTextColor="#2c6e49"
									maxLength={10}
								/>
							</View>
						</View>

					)}

					<View style={styles.saveButtonContainer}>
						<TouchableOpacity onPress={accountTypeChanged ? handleAccountTypeChangePress : handleSave} style={styles.saveButton}>
							<Text style={styles.saveButtonText}>{accountTypeChanged ? "Change Account Type" : "Save"}</Text>
						</TouchableOpacity>
					</View>
				</View>

				{/* Save success popup */}
				{showSavePopup && (
					<Animated.View
						style={[
							styles.savePopup,
							{ opacity: savePopupAnim }, // Bind opacity to animated value
						]}>
						<Text style={styles.savePopupText}>Profile Updated Successfully!</Text>
					</Animated.View>
				)}
				{/* // Organization Type Selection Modal */}
				<Modal style={styles.modalContainer} animationType="slide" transparent={true} visible={isOrgTypeModalVisible} onRequestClose={toggleOrgTypeModal}>
					<View style={styles.modalContainer}>
						<TouchableOpacity style={styles.closeButton} onPress={toggleOrgTypeModal}>
							<Text style={styles.closeButtonText}>X</Text> {/* Simple text for close button, can be replaced with an icon */}
						</TouchableOpacity>
						<Text style={styles.modalTitle}>Add New Organization</Text>

						<TextInput style={styles.modalField} value={customOrgName} onChangeText={setCustomOrgName} placeholder="Organization Name" placeholderTextColor="#2c6e49" />

						<TextInput
							style={styles.modalField}
							value={orgAddress} // Add this state variable
							onChangeText={setOrgAddress} // Add this handler function
							placeholder="Address"
							placeholderTextColor="#2c6e49"
						/>

						<TextInput
							style={styles.modalField}
							value={orgWebsite} // Add this state variable
							onChangeText={setOrgWebsite} // Add this handler function
							placeholder="Website"
							placeholderTextColor="#2c6e49"
						/>

						{/* <Text style={styles.modalText}>Select Organization Type</Text> */}
						<View style={styles.modalDropDown}>
							<DropDownPicker
								open={openDropDown}
								value={selectedOrgType}
								items={orgTypeItems}
								setOpen={setOpenDropDown}
								setValue={onOrgTypeChange}
								setItems={setOrgTypeItems}
								style={{
									width: "100%", borderWidth: 1,
									borderRadius: 27.5,
									padding: 10,
									marginVertical: 10,
									borderColor: "#e4e4e4",
									fontFamily: "Orpheus Pro",
									color: "#2c6e49",
									backgroundColor: "#fefde2",
									paddingHorizontal: 25,
									fontSize: isMobile ? 16 : 24, fontFamily: fontFamilies.Bodoni72Smallcaps
								}}
								dropDownContainerStyle={[styles.dropdownContainerStyle]} // Adjust the container style
								dropDownDirection="TOP" // Make the dropdown expand upwards
								placeholder={organizationType} // Custom placeholder text
								placeholderTextColor="#2c6e49"
								placeholderStyle={{ fontFamily: fontFamilies.Bodoni72Smallcaps, fontSize: isMobile ? 16 : 20 }}
								itemStyle={{
									justifyContent: "flex-start", // Align text to the start
									fontFamily: fontFamilies.Bodoni72Smallcaps, // Custom font
									fontSize: 18, // Custom font size
								}}
								labelStyle={{
									fontFamily: fontFamilies.Bodoni72Smallcaps, // Custom font
									fontSize: 20, // Custom font size
									color: "#2c6e49", // Custom text color
								}}
							/>
						</View>

						<TouchableOpacity onPress={submitCustomOrganization} style={styles.modalButton}>
							<Text style={styles.modalButtonText}>Add Organization</Text>
						</TouchableOpacity>
						{showAddOrgSuccess && (
							<Animated.View
								style={[
									styles.addOrgSuccessPopup,
									{ opacity: addOrgSuccessAnim }, // Bind opacity to animated value
								]}>
								<Text style={styles.addOrgSuccessPopupText}>Organization Added Successfully!</Text>
							</Animated.View>
						)}
					</View>
				</Modal>
			</ImageBackground>
		</ScrollView>
	);
};

export default Profile;
