import React from "react";
import { View, Text, StyleSheet, ImageBackground } from "react-native";

const ComingSoon = () => {
	return (
		<View style={styles.container}>
			<ImageBackground source={require("../assets/Landing Background.png")} style={styles.backgroundImage}>
				<Text style={styles.text}>Coming Soon!</Text>
			</ImageBackground>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
  height: "100%",
  width: "100%",
		backgroundColor: "#f2f2f2", // Light grey background
		zIndex: 1000,
  position: "absolute"
	},
	text: {
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize: 75,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
 backgroundImage: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		resizeMode: "contain",
	},
});

export default ComingSoon;
