import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ImageBackground, Modal } from 'react-native';
import Popup from './Popup';
import backgroundImage from '../assets/Landing Background.png';
import { useNavigation } from '@react-navigation/native';

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const PromptSignUp = ({ isVisible, onClose, originalPage, redirectPage }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const navigation = useNavigation();

    const handleSignUp = () => {
        setPopupType('register');
        setShowPopup(true);
    };

    const handleSignIn = () => {
        setPopupType('login');
        setShowPopup(true);
    };

    const handleClose = () => {
        setShowPopup(false);
        setPopupType('');
        onClose();
    };

    useEffect(() => {
        if (!isVisible) {
            setShowPopup(false);
        }
    }, [isVisible]);

    const handlePopupClose = (success) => {
        setShowPopup(false);
        if (success && redirectPage) {
            navigation.navigate(redirectPage);
        } 
        else if (success && originalPage) {
            navigation.navigate(originalPage);
        }
        onClose();
    };

    return (
        <Modal visible={isVisible} transparent={true} animationType="slide" onRequestClose={onClose}>
            <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
                <View style={styles.overlay}>
                    <View style={styles.content}>
                        <Text style={styles.title}>Welcome to MyTassel</Text>
                        <Text style={styles.subtitle}>Please sign in or register to access this feature! {'\n'}Signing up is free and gives you full access to all features we have to offer!</Text>
                        <TouchableOpacity style={styles.button} onPress={handleSignIn}>
                            <Text style={styles.buttonText}>Sign in</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={handleSignUp}>
                            <Text style={styles.buttonText}>Register</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity style={styles.closeButton} onPress={handleClose}>
                        <Text style={styles.closeButtonText}>X</Text>
                    </TouchableOpacity>
                </View>
                {showPopup && <Popup type={popupType} onClose={(success) => handlePopupClose(success)} />}
            </ImageBackground>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backgroundImage: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 20,
        borderRadius: 25,
        textAlign: 'center',
        position: 'relative',
        width: '80%',
        alignItems: 'center',
    },
    content: {
        color: '#fff',
    },
    title: {
        fontSize: 52,
        fontWeight: 'bold',
        color: "#fefde2",
        marginBottom: 10,
        textAlign: "center",
        fontFamily: fontFamilies.Bodoni72Smallcaps,
    },
    subtitle: {
        fontSize: 24,
        color: "#fefde2",
        marginBottom: 20,
        textAlign: "center",
        fontFamily: fontFamilies.Bodoni72Smallcaps,
    },
    button: {
        margin: 10,
        padding: 10,
        backgroundColor: "#4c956c",
        borderRadius: 5,
    },
    buttonText: {
        color: "#fefde2",
        fontSize: 24,
        textAlign: "center",
        fontFamily: fontFamilies.Bodoni72Smallcaps,
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        left: 10,
        backgroundColor: 'transparent',
        color: "#fefde2",
        borderColor: "#fefde2",
        borderWidth: 1,
        borderRadius: 100,
        padding: 10,
        paddingHorizontal: 15,
    },
    closeButtonText: {
        color: "#fefde2",
        fontSize: 18,
        fontFamily: fontFamilies.Bodoni72Smallcaps,
    },
});

export default PromptSignUp;