import React, { useEffect, useState } from 'react';
import { View, Text, ImageBackground, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { firebaseConfig, app } from "../firebase";
import { getAuth, applyActionCode, updateEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

const db = getFirestore(app);

const VerifyEmail = () => {
  const [message, setMessage] = useState('Verifying your email...');
  const auth = getAuth();
  const navigation = useNavigation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get('oobCode');
    const uid = queryParams.get('uid');

    if (oobCode && uid) {
      applyActionCode(auth, oobCode)
        .then(async () => {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const newEmail = userData.email;

            if (auth.currentUser.email !== newEmail) {
              await updateEmail(auth.currentUser, newEmail);
            }

            const updatedUserData = {
              ...userData,
              hasConfirmedEmail: true,
              newEmail: "", // Clear the newEmail email
            };

            await setDoc(doc(db, "users", newEmail), updatedUserData);

            setMessage("Email verified successfully.");
          } else {
            setMessage("No such user data!");
          }
        })
        .catch((error) => {
          setMessage(`Error verifying email: ${error.message}`);
        });
    } else {
      setMessage("Invalid verification link.");
    }
  }, [auth]);

  return (
    <ImageBackground source={require("../assets/Landing Background.png")} style={styles.backgroundImage}>
      <View style={styles.body}>
        <View style={styles.container}>
          <Text style={styles.message}>{message}</Text>
          {message === 'Verifying your email...' && <ActivityIndicator size="large" color="#0000ff" />}
          <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('My Tassel - Welcome')}>
            <Text style={styles.buttonText}>Return to Home Page</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    backgroundColor: "#f5f5f5",
  },
  container: {
    backgroundColor: '#fefde2', // Beige color
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  message: {
    fontSize: 18,
    textAlign: 'center',
    margin: 20,
  },
  button: {
    backgroundColor: '#4c956c',
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
});

export default VerifyEmail;
