import React, { useState, useEffect, useRef } from "react";
import { View, Image, TouchableOpacity, Text, StyleSheet, Alert, Dimensions, Animated } from "react-native";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { app } from "../../firebase";


const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;


const ImageGallery = ({ isEditMode, onImagesUpdated, initialImages, isPreview }) => {
	// URLs for preview images
	const previewImages = [
		require("../../assets/Popup Background.png"), // Local image
		{ uri: "https://source.unsplash.com/KAaEWMqFc8A" },
		{ uri: "https://source.unsplash.com/iggWDxHTAUQ" },
		{ uri: "https://source.unsplash.com/1VqHRwxcCCw" },
	];

	const [images, setImages] = useState(isPreview ? previewImages : initialImages || ["https://via.placeholder.com/400"]);
	const [currentImage, setCurrentImage] = useState(0);
	const fileInputRef = useRef(null);

	const [fadeAnim] = useState(new Animated.Value(1)); // Initial opacity value

	const handleAddImage = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const uniqueFileName = `eventPageImages/${Date.now()}-${file.name}`;
			const storage = getStorage(app);
			const storageRef = ref(storage, uniqueFileName);

			try {
				// Upload the file
				await uploadBytes(storageRef, file);

				// Try to retrieve the download URL to verify existence
				const url = await getDownloadURL(storageRef);

				// Update the images state with the new URL
				setImages((prevImages) => {
					const updatedImages = [...prevImages, url];
					if (onImagesUpdated) {
						onImagesUpdated(updatedImages); // Ensure this updates the database
					}
					return updatedImages;
				});
			} catch (error) {
				console.error("Error uploading or retrieving image:", error);
				Alert("Error uploading image, please try again later.");
			}
		}
	};


	const handleDeleteImage = async (image, index) => {
		const storage = getStorage(app);
		const imageRef = ref(storage, image);
		await deleteObject(imageRef);
		const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
		setImages(updatedImages);
		if (onImagesUpdated) {
			onImagesUpdated(updatedImages);
		}
	};

	const fadeIn = () => {
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const fadeOut = (callback) => {
		Animated.timing(fadeAnim, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start(() => {
			if (callback) callback();
		});
	};

	useEffect(() => {
		let interval;
		if (images.length > 1) {
			interval = setInterval(() => {
				fadeOut(() => {
					setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
					fadeIn();
				});
			}, 4000); // Change the image every 4 seconds
		}

		return () => clearInterval(interval);
	}, [images]);

	useEffect(() => {
		setImages(isPreview ? previewImages : initialImages || ["https://via.placeholder.com/400"]);
	}, [initialImages, isPreview]);

	// Ensure the component re-renders with updated images
	useEffect(() => {
		if (initialImages) {
			setImages(initialImages);
		}
	}, [initialImages]);

	return (
		<View style={isPreview ? styles.previewGalleryContainer : styles.galleryContainer}>
			{isEditMode && !isPreview && (
				<TouchableOpacity onPress={handleAddImage} style={[styles.addButton, styles.widgetShadow]}>
					<Text style={styles.addButtonText}>Add Photos</Text>
				</TouchableOpacity>
			)}
			<input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={handleFileChange} accept="image/*" />
			<View style={{ position: "relative", paddingTop: 10 }}>
				{isEditMode && !isPreview && (
					<TouchableOpacity onPress={() => handleDeleteImage(images[currentImage], currentImage)} style={styles.deleteImageButton}>
						<Text style={styles.deleteButton}>Remove Image</Text>
					</TouchableOpacity>
				)}
				<Animated.Image
					source={{ uri: images[currentImage] }}
					style={[isPreview ? styles.previewImageStyle : styles.imageStyle, styles.widgetShadow, { opacity: fadeAnim }]}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	previewGalleryContainer: {
		borderRadius: 10,
		padding: 5,
		width: isMobile ? "90vw" : "90vw",
		height: isMobile ? "90vw" : "90vw",
	},
	previewImageStyle: {
		width: isMobile ? "90vw" : 100,
		height: isMobile ? "90vw" : 100,
		borderRadius: 10,
		zIndex: 98
	},

	galleryContainer: {
		borderRadius: 25,
	},
	imageStyle: {
		width: isMobile ? "90vw" : 650,
		height: isMobile ? "90vw" : 650,
		marginBottom: 10,
		borderRadius: 25,
		zIndex: 98
	},
	addButton: {
		padding: isMobile ? 5 : 10,
		backgroundColor: '#4C956C',
		borderRadius: 5,
		marginTop: 20, // Adjust as needed
	},
	addButtonText: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
		fontSize: isMobile ? 16 : 20,
	},
	deleteImageButton: {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: "red",
		padding: 5,
		borderRadius: 5,
		color: "white",
		fontWeight: "bold",
		zIndex: 99,
	},
	deleteButton: {
		color: "white",
		fontWeight: "bold",
	},
	widgetShadow: {
		shadowColor: "rgba(0, 0, 0, 0.5)",
		shadowOffset: { width: 0, height: 6 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		borderRadius: 25
	},
});

export default ImageGallery;