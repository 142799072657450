import React, { useState } from "react";
import { View, TextInput, StyleSheet, Dimensions } from "react-native";

const fontFamilies = {
    Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;


const TextBox = ({ initialText, onTextChange, textColor }) => {
    const [text, setText] = useState(initialText || "");

    const handleChange = (newText) => {
        setText(newText);
        onTextChange(newText); // Notify parent component about the change
    };

    return (
        <View style={styles.container}>
            <TextInput
                value={text}
                onChangeText={handleChange}
                style={[styles.textInput, { color: textColor }]}
                multiline
                placeholder="Type..."
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#FEFCE0",
        borderRadius: 25,
        paddingHorizontal: "auto",
    },
    textInput: {
        paddingHorizontal: 10,
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: isMobile ? 20 : 40,
        marginTop: 25
    },
});

export default TextBox;
