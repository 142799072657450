import React, { useState, useEffect, Alert } from "react";
import { View, Text, Pressable, StyleSheet, Image, FlatList, TextInput, Dimensions } from "react-native";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { app } from "../../firebase";
import { useNavigation, useRoute } from '@react-navigation/native'; // Ensure you import useNavigation and useRoute

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const Catalogue = ({ onAddItems, isFromRegistry }) => {
	const db = getFirestore(app);
	const [catalogueItems, setCatalogueItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [viewCategories, setViewCategories] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 6;
	const totalItemsCount = catalogueItems.length;
	const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);

	const categories = ["Bedroom", "Bathroom", "Decor", "Health & Personal Care", "Kitchen", "Laundry & Cleaning", "Electronics & Office Supplies", "Storage & Organization"];

	const subCategories = {
		"Bedroom": ["Sheets", "Comforter", "Bed In a Bag", "Pillow", "Pillow Protector", "Body Pillow", "Mattress Topper", "Mattress Protector", "Throw Blanket", "Study Pillow", "Misc"],
		"Bathroom": ["Towel Set", "Bluetooth Speaker", "Shower Caddy", "Shower Shoes", "Bathrobe", "Shower Wrap", "Hair Wrap", "Bathroom Rug", "Shower Curtain", "Tension Rod", "Misc"],
		"Decor": ["Throw Rug", "Throw Pillows", "Desk Lamp", "Floor Lamp", "Lounge Seating", "Mirror", "Picture Frames", "String Lights", "Tension Rod", "Vanity Mirror", "Misc"],
		"Health & Personal Care": ["Hair Dryer", "Styling Tools", "Hair Removal", "First Aid Kit", "Pain Reliever", "Sewing Kit", "Exercise Equipment", "Humidifier", "Diffuser", "Diffuser Oils", "Air Purifier", "Noise Machine", "Tool Set", "Misc"],
		"Kitchen": ["Microwave", "Refrigerator", "Personal Refrigerator", "Coffee Maker", "Rice Cooker", "Cooking Tools", "Smoothie Maker", "Air Fryer", "Dishes", "Silverware", "Water Bottles", "Travel Mug", "Can Opener", "Water Filtering", "Mugs", "Snack Bundles", "Food Storage", "Pots/Pans", "Cooking Utensils", "Misc"],
		"Laundry & Cleaning": ["Cleaning Bundle", "Laundry Basket", "Hamper", "Laundry Detergent", "Odor Eliminator", "Steamer", "Ironing Board", "Vacuums", "Laundry Bag", "Broom & Dust Pan", "Garbage Can", "Misc"],
		"Electronics & Office Supplies": ["Television", "Laptop", "Flash Drive", "Hard Drive", "Extension Cords", "Headphones", "Printer", "Alarm Clock", "Bluetooth Speaker", "Personal Alarm", "Room Alarm", "Firestick", "Amazon Speakers", "Day Planner", "Backpack", "Pens", "Pencils", "Highlighters", "Notebooks", "Stapler", "Scissors", "Printer paper", "Sticky Notes", "Dry Erase Markers", "Desk Set", "Misc"],
		"Storage & Organization": ["Bedside Shelf/Caddy", "Closet Organization", "Shoe Storage/Racks", "Hangers", "Trunk/Storage", "Underbed Storage", "Drawers", "Over Door Organization", "Rolling Cart", "Desk Organizer", "Wall Key Organizer", "Moving Bags", "Luggage", "Misc"]
	};

	const navigation = useNavigation();
	const route = useRoute();
	const { isFromLandingPage, isFromHub } = route.params || {}; // Get the parameter from the route

	const handleBackToLanding = () => {
		navigation.navigate('My Tassel - Welcome'); // Adjust 'LandingPage' to your actual landing page route name
	};

	const handleBackToHub = () => {
		navigation.navigate('Hub'); // Adjust 'Hub' to your actual hub page route name
	};

	useEffect(() => {
		if (selectedCategory) {
			const fetchCatalogueItems = async () => {
				const q = query(collection(db, "catalogueItems"), where("category", "==", selectedCategory));
				const querySnapshot = await getDocs(q);
				const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
					.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
						(!selectedSubCategory || item.name.toLowerCase().includes(selectedSubCategory.toLowerCase()) || item.description.toLowerCase().includes(selectedSubCategory.toLowerCase())));

				// Function to extract keywords from a string
				const extractKeywords = (text) => {
					const stopWords = new Set(["the", "and", "a", "an", "of", "in", "to", "with", "for", "on", "at", "by", "from", "up", "about", "into", "over", "after", "under", "above", "below", "is", "are", "was", "were", "be", "been", "being", "have", "has", "had", "do", "does", "did", "will", "would", "shall", "should", "can", "could", "may", "might", "must", "or", "as", "if", "when", "than", "because", "while", "where", "so", "though", "since", "until", "whether", "but", "nor", "like", "once", "unless", "except", "just", "even", "also", "only", "own", "same", "so", "than", "too", "very", "s", "t", "can", "will", "just", "don", "should", "now"]);
					return text.toLowerCase().split(/\W+/).filter(word => !stopWords.has(word) && word.length > 1);
				};

				// Function to calculate Jaccard similarity between two sets
				const jaccardSimilarity = (setA, setB) => {
					const intersection = new Set([...setA].filter(x => setB.has(x)));
					const union = new Set([...setA, ...setB]);
					return intersection.size / union.size;
				};

				// Extract keywords for each item
				const itemsWithKeywords = items.map(item => ({
					...item,
					keywords: new Set([
						...extractKeywords(item.name),
						...extractKeywords(item.description)
					])
				}));

				// Hierarchical clustering based on Jaccard similarity
				const clusters = [];
				itemsWithKeywords.forEach(item => {
					let addedToCluster = false;
					for (const cluster of clusters) {
						const similarity = jaccardSimilarity(item.keywords, cluster.keywords);
						if (similarity > 0.5) { // Adjust threshold as needed
							cluster.items.push(item);
							cluster.keywords = new Set([...cluster.keywords, ...item.keywords]);
							addedToCluster = true;
							break;
						}
					}
					if (!addedToCluster) {
						clusters.push({ items: [item], keywords: item.keywords });
					}
				});

				// Flatten the clusters into a single array of items
				const sortedItems = clusters.flatMap(cluster => cluster.items);

				setCatalogueItems(sortedItems); // Set the state with sorted items
			};
			fetchCatalogueItems();
		}
	}, [selectedCategory, searchQuery, selectedSubCategory]);

	const handleSelectCategory = (category) => {
		setSelectedCategory(category);
		setViewCategories(false);
		setCurrentPage(1); // Reset to first page when category changes
	};

	const handleBackToCategories = () => {
		setViewCategories(true);
		// Optionally reset selected category here if needed
	};

	const handleSelectItem = (item) => {
		const isItemSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);
		if (isItemSelected) {
			setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		// Trigger a visual feedback update, e.g., by changing the state that affects the item's style
	};

	const truncateDescription = (description, wordLimit = 15) => {
		// First, trim to ensure there's no leading/trailing whitespace
		let cleanedDescription = description.trim();

		// Define the prefix to look for, in a case-insensitive manner
		const aboutThisItemPrefix = "About this item";

		// Use a regex to replace "About this item" and any number of following spaces with an empty string
		cleanedDescription = cleanedDescription.replace(new RegExp(`^${aboutThisItemPrefix}\\s*`, 'i'), '');

		// Split the remaining description into words, considering any amount of whitespace as a single splitter
		const words = cleanedDescription.split(/\s+/);

		// Truncate the description to the word limit, if necessary
		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}

		console.log(cleanedDescription)

		return cleanedDescription;
	};

	const truncateTitle = (title, wordLimit = 6) => {
		const words = title.split(" ");
		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}
		return title;
	};

	const paginatedItems = () => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		return catalogueItems.slice(startIndex, startIndex + itemsPerPage);
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
		}
	};

	const handleAddItems = async () => {
		await onAddItems(selectedItems);
		setSelectedItems([]); // Clear selected items after adding
	};

	const handleSelectSubCategory = (subCategory) => {
		setSelectedSubCategory(subCategory);
		setCurrentPage(1); // Reset to first page when subcategory changes
	};

	const handleSearch = (query) => {
		setSearchQuery(query);
	};

	const renderSubCategories = (category) => {
		return (
			<FlatList
				data={subCategories[category]}
				renderItem={({ item: subCategory }) => (
					<Pressable
						style={({ hovered }) => [styles.subCategoryContainer, hovered && styles.subCategoryContainerHover]}
						onPress={() => handleSelectSubCategory(subCategory)}
					>
						<Text style={({ hovered }) => [styles.subCategoryText, hovered && styles.subCategoryTextHover]}>
							{subCategory}
						</Text>
					</Pressable>
				)}
				keyExtractor={(item) => item}
				horizontal={true}
				contentContainerStyle={{ ...styles.subCategoryListContainer }}
			/>
		);
	};

	return (
		<View style={styles.container}>
			{isFromLandingPage && (
				<Pressable style={styles.backButton} onPress={handleBackToLanding}>
					<Text style={styles.backButtonText}>Back to Landing</Text>
				</Pressable>
			)}


			{isFromHub && (
				<Pressable style={styles.backButton} onPress={handleBackToHub}>
					<Text style={styles.backButtonText}>Back to Hub</Text>
				</Pressable>
			)}

			{viewCategories ? (
				<>
					<FlatList
						data={categories}
						renderItem={({ item: category }) => (
							<Pressable
								style={({ hovered }) => [styles.categoryContainer, hovered && styles.categoryContainerHover]}
								onPress={() => handleSelectCategory(category)}
							>
								<Image source={require(`../../assets/Catalogue/${category}.jpg`)} style={styles.categoryImage} />
								<View style={styles.textContainer}>
									<Text style={styles.categoryText}>{category}</Text>
								</View>
							</Pressable>
						)}
						keyExtractor={(item) => item}
						numColumns={4}
						contentContainerStyle={{ ...styles.innerContainer }}
					/>

				</>
			) : (
				<View style={styles.itemsContainer}>
					<TextInput
						style={styles.searchBar}
						placeholder="Search items..."
						value={searchQuery}
						onChangeText={handleSearch}
					/>

					{selectedCategory && renderSubCategories(selectedCategory)}

					<View style={styles.paginationContainer}>
						<Pressable onPress={handlePreviousPage} disabled={currentPage <= 1} style={styles.paginationButton}>
							<Text style={styles.paginationText}>Previous</Text>
						</Pressable>
						<Text style={styles.paginationSubText}>
							Page {currentPage} of {totalPages}
						</Text>
						<Pressable onPress={handleNextPage} disabled={currentPage >= totalPages} style={styles.paginationButton}>
							<Text style={styles.paginationText}>Next</Text>
						</Pressable>
					</View>



					<FlatList
						data={paginatedItems()}
						renderItem={({ item }) => (
							<Pressable
								onPress={() => handleSelectItem(item)}
								style={({ hovered }) => [
									styles.itemCard,
									selectedItems.find((selectedItem) => selectedItem.id === item.id) ? styles.itemSelected : {},
									hovered && styles.itemHover
								]}
							>
								{item.image && <Image source={{ uri: item.image }} style={styles.itemImage} />}
								<Text style={styles.itemName}>{truncateTitle(item.name)}</Text>
								<Text style={styles.itemPrice}>{`${item.price}`}</Text>
								<Text style={styles.itemDescription}>{truncateDescription(item.description)}</Text>
								<Pressable
									style={styles.seeProductButton}
									onPress={(e) => {
										e.stopPropagation(); // Prevent parent onPress
										window.open(item.url, '_blank');
									}}
								>
									<Text style={styles.seeProductButtonText}>View Product</Text>
								</Pressable>
							</Pressable>
						)}
						keyExtractor={(item) => item.id.toString()}
						numColumns={3}
					/>
					<View style={styles.paginationContainer}>
						<Pressable onPress={handlePreviousPage} disabled={currentPage <= 1} style={styles.paginationButton}>
							<Text style={styles.paginationText}>Previous</Text>
						</Pressable>
						<Text style={styles.paginationSubText}>
							Page {currentPage} of {totalPages}
						</Text>
						<Pressable onPress={handleNextPage} disabled={currentPage >= totalPages} style={styles.paginationButton}>
							<Text style={styles.paginationText}>Next</Text>
						</Pressable>
					</View>

					<Pressable style={styles.backButton} onPress={handleBackToCategories}>
						<Text style={styles.backButtonText}>Back to Categories</Text>
					</Pressable>

					<Pressable style={styles.addButton} onPress={handleAddItems}>
						<Text style={styles.addButtonText}>Add Selected Items</Text>
					</Pressable>
				</View>
			)}
			<View style={styles.disclaimerContainer}>
				<Text style={styles.disclaimer}>Disclaimer: As an Amazon Associate MyTassel earns commissions from qualifying purchases on our site.</Text>
			</View>
		</View>
	);
};

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100%",
		height: "100%",
		top: 0,
	},
	innerContainer: {
		flexDirection: "column", // Align children in a row
		flex: 1,
		alignItems: "left",
		alignSelf: "center",
		height: "100%",
		width: "100%",
		top: 0,
	},
	categoryContainer: {
		backgroundColor: "#ffffff", // White background for category cards
		borderRadius: 25, // Adjust the radius as needed
		margin: 5, // Smaller margin for tighter packing
		overflow: "hidden",
		elevation: 3, // Shadow for Android
		shadowColor: "#000", // Shadow for iOS
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 2,
		width: isMobile ? "23%" : "23%", // Adjust width for 3 items per row
		height: isMobile ? 300 : 600,
		aspectRatio: 1, // Keep the boxes square-shaped
		alignItems: "center", // Center items horizontally
		justifyContent: "center", // Center items vertically
		margin: isMobile ? 2.5 : 10,
		marginTop: isMobile ? 25 : 100,
		transition: 'transform 0.3s ease',
	},
	categoryContainerHover: {
		transform: 'scale(1.05)',
	},
	categoryImage: {
		width: isMobile ? "100%" : "100%",
		height: isMobile ? "100%" : "100%", // Adjust height to leave space for text
		resizeMode: "cover",
	},
	textContainer: {
		position: "absolute",
		bottom: 0,
		backgroundColor: "#FFFFFFA0", // Semi-transparent white
		width: "100%",
		paddingVertical: 8,
	},
	categoryText: {
		fontSize: isMobile ? 12 : 18,
		fontWeight: "bold",
		color: "#333",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		// color:  "#fefde2",
	},
	itemsContainer: {
		marginTop: isMobile ? 25 : 50,
		flex: 1,
		flexDirection: "column",
		flexWrap: "wrap",
		justifyContent: "center", // Center items horizontally
		alignItems: "center", // Align items vertically
		paddingHorizontal: 25, // Space for widget menu
		// paddingVertical: 75, // Space for widget menu
		top: 0,
		position: "relative", // Ensure this is set
		width: "100%",
	},
	itemCard: {
		backgroundColor: "#fefde2",
		borderRadius: 10, // Rounded corners
		padding: isMobile ? 5 : 15, // Inner spacing
		marginVertical: isMobile ? 5 : 20, // Increased vertical margin for more space
		marginHorizontal: isMobile ? 5 : 20, // Horizontal spacing from screen edges
		shadowColor: "#171717", // Shadow color
		shadowOffset: { width: 0, height: 4 }, // Shadow offset
		shadowOpacity: 0.2, // Shadow opacity
		shadowRadius: 3, // Shadow blur radius
		elevation: 5, // Elevation for Android
		alignItems: "center", // Center items horizontally in the card
		justifyContent: 'space-between', // Ensures space distribution
		maxWidth: "30%",
		minHeight: isMobile ? 300 : 600, // Use minHeight for flexibility
		transition: 'transform 0.3s ease',
	},

	itemSelected: {
		borderColor: "green",
		borderWidth: 3,
	},
	itemImage: {
		width: "100%",
		height: "50%", // Fixed height for item images
		resizeMode: "cover",
		borderRadius: 10, // Round corners of images
		paddingBottom: 40,
	},

	itemName: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 18 : 24, // Font size
		fontWeight: "bold", // Font weight
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: 5, // Spacing between name and description
	},

	itemDescription: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: 18, // Font size
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: 10, // Spacing between description and price
	},

	itemPrice: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: 18, // Font size
		fontWeight: "bold", // Font weight
		color: "#4c956c", // Price text color, matching the theme
		textAlign: "center", // Center text
	},
	backButton: {
		position: "absolute",
		top: 20,
		left: 20,
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		zIndex: 10,
	},
	backButtonText: {
		fontSize: 16,
		color: "#ffffff",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	// Updated styles.addButton to move it to the top
	addButton: {
		position: "absolute",
		top: 20, // Moved from bottom to top
		right: 20,
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		zIndex: 10,
	},
	addButtonText: {
		fontSize: 16,
		color: "#ffffff",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	searchBar: {
		fontSize: 18,
		padding: 10,
		marginVertical: 10,
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 50,
		width: "80%",
		alignSelf: "center",
		backgroundColor: "#fefde2",
	},
	paginationContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingVertical: 10,
	},
	paginationButton: {
		marginHorizontal: 20,
		paddingVertical: 10,
		paddingHorizontal: 20,
		backgroundColor: "#4c956c",
		borderRadius: 5,
	},
	paginationSubText: {
		fontSize: 16,
		color: "#4c956c",
	},
	paginationText: {
		fontSize: 16,
		color: "#ffffff",
	},
	seeProductButton: {
		marginTop: 10,
		paddingVertical: 8,
		paddingHorizontal: 16,
		backgroundColor: '#4c956c', // Example green color
		borderRadius: 5,
		alignSelf: 'center',
	},
	seeProductButtonText: {
		color: '#ffffff',
		fontSize: 16,
		textAlign: 'center',
	},
	disclaimer: {
		flex: 1,
		fontFamily: "Orpheus Pro",
		fontSize: 18,
		textAlign: "center",
		justifyContent: "center",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 15,
		fontStyle: "italic",
		top: 25,
	},
	disclaimerContainer: {
		justifyContent: "center",
		alignItems: "center",
		marginTop: 20,
		marginBottom: 20,
	},
	itemHover: {
		transform: [{ scale: 1.05 }],
	},
	subCategoryContainer: {
		backgroundColor: "#fefde2",
		borderRadius: 15,
		margin: 5,
		overflow: "hidden",
		elevation: 3,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 2,
		width: isMobile ? "30%" : "auto", // Adjust width for smaller subcategory items
		height: isMobile ? 50 : 50,
		padding: 5,
		alignItems: "center",
		justifyContent: "center",
		transition: 'transform 0.3s ease',
	},
	subCategoryContainerHover: {
		transform: 'scale(1.05)',
		backgroundColor: "#4C956C",
	},
	subCategoryText: {
		fontSize: isMobile ? 10 : 14,
		fontWeight: "bold",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		paddingHorizontal: 10
	},
	subCategoryTextHover: {
		fontSize: isMobile ? 10 : 14,
		fontWeight: "bold",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "white",
	paddingHorizontal: 10
	},
	subCategoryListContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingVertical: 10,
		flexWrap: "wrap",
		gap: 5,
		width: "100%",
		paddingHorizontal: "20%",
		maxHeight: "25%",
	},
});

export default Catalogue;
