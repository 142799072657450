import React from "react";
import { UserProvider } from "./Widgets/UserContext";
import Navigation from "./Widgets/Navigation";
import { BlurProvider } from "./Widgets/BlurContext";

const App = () => {
  return (
    <BlurProvider>
      <UserProvider>
        <Navigation />
      </UserProvider>
    </BlurProvider>
  );
};

export default App;