// Checkout.js
import React, { useState, Alert } from "react";
import { Modal, View, Text, TextInput, Pressable, Image, StyleSheet } from "react-native";
import { useUser } from "../../Widgets/UserContext";
import firebase from "firebase/app";
import 'firebase/storage'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios";

const Checkout = ({ isVisible, onClose, selectedECard, pageOwner }) => {
	const [amount, setAmount] = useState("");
	const processingFee = 5.0;
	const recipientEmail = pageOwner.email;
	const recipientName = pageOwner.firstName;

	const stripe = useStripe();
	const elements = useElements();

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = "https://api.mytassel.com";

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;


	const { currentUser, currentUserData } = useUser();

	if (!currentUserData) {
		return null;
	}

	console.log("Recipient:", recipientEmail);
	console.log("Sender:", currentUserData.email);
	console.log("Card:", selectedECard);


	const handleSubmit = async () => {
		const contributionAmount = parseFloat(amount) || 0;
		if (contributionAmount > 0) {
			try {
				// Here, you might process the payment first
				await processPayment(contributionAmount + processingFee);
				// After successful payment, send the eCard
				await sendECard({
					frontImageUrl: selectedECard?.frontImageUrl,
					backImageUrl: selectedECard?.backImageUrl,
					contributionAmount: amount,
					recipientEmail: recipientEmail, // Assuming this is passed as a prop
					senderEmail: currentUserData?.email, // This should be dynamically set based on your app logic
					senderName: currentUserData?.firstName,
					name: recipientName
				});
				onClose(); // Optionally close the modal after contribution
				alert("Success", "Your contribution and eCard have been successfully sent!");
			} catch (error) {
				console.error("Error during contribution or sending eCard:", error);
				alert("Error", "There was a problem with your contribution or sending your eCard. Please try again.");
			}
		} else {
			alert("Invalid Amount", "Please enter a valid contribution amount greater than $0.");
		}
	};

	// Simulate processing a payment via Stripe
	const processPayment = async (contributionAmount) => {
		try {
			// Calculate the total amount (contribution + platform fee)
			const totalAmount = contributionAmount + 5;

			// Create a PaymentIntent on the server
			const response = await axios.post(`${SERVER_URL}/create-payment-intent`, {
				amount: totalAmount * 100, // Convert to cents
				currency: "usd",
				sellerAccountId: pageOwner.stripeAccountId, // Pass the Stripe Account ID of the connected account
			});

			const clientSecret = response.data.clientSecret;

			// Assuming you have a form for card details, you need to create a payment method first
			const cardElement = elements.getElement(CardElement);
			const {paymentMethod, error: paymentMethodError} = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});

			if (paymentMethodError) {
				console.log("Payment method creation failed:", paymentMethodError);
				return;
			}

			// Confirm the PaymentIntent using the client secret and the created payment method ID
			const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
				payment_method: paymentMethod.id,
			});

			if (error) {
				console.log("Payment failed:", error);
				// Handle failure and update UI
			} else if (paymentIntent.status === "succeeded") {
				console.log("Payment successful:", paymentIntent);
				// Increment contribution amount in the database
				await incrementContributionAmount(contributionAmount);
			}
		} catch (error) {
			console.error("Error processing payment:", error);
			// Handle error and update UI
		}
	};

	const incrementContributionAmount = async (amount) => {
		try {
			const db = getFirestore();
			const pageOwnerRef = doc(db, "users", pageOwner.email);
			const pageOwnerDoc = await getDoc(pageOwnerRef);

			if (pageOwnerDoc.exists()) {
				const currentAmount = pageOwnerDoc.data().pages.registryPageConfig.goalTracker.amountContributed || 0;
				await updateDoc(pageOwnerRef, {
					"pages.registryPageConfig.goalTracker.amountContributed": currentAmount + amount
				});
			} else {
				await setDoc(pageOwnerRef, {
					"pages.registryPageConfig.goalTracker.amountContributed": amount
				});
			}

			console.log("Contribution amount updated successfully");
			alert("Success", "Your contribution has been successfully sent!");
			// Trigger a rerender or update the state to reflect the new contribution amount
			// This can be done by updating a state variable or using a context
		} catch (error) {
			console.error("Error updating contribution amount:", error);
			alert("Error", "There was a problem with your contribution. Please try again.");
		}
	};

	const sendECard = async (eCardData) => {
		try {
			const response = await fetch(`${SERVER_URL}/send-ecard-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(eCardData),
			});

			if (!response.ok) throw new Error("Failed to send eCard");
		} catch (error) {
			console.error("Error sending eCard:", error);
			throw error; // Rethrow to handle in the calling function
		}
	};

	return (
		<Modal visible={isVisible} animationType="slide" transparent={true}>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					{selectedECard &&
						<View style={styles.eCardContainer}>
							<Image source={{ uri: selectedECard.frontImageUrl }} style={styles.eCardPreview} />
							<Image source={{ uri: selectedECard.backImageUrl }} style={styles.eCardPreview} />
						</View>
					}
					<TextInput style={styles.input} placeholder="Contribution Amount" value={amount} onChangeText={setAmount} keyboardType="numeric" />
					<View style={styles.summary}>
						<Text style={styles.summaryText}>Contribution: ${amount || "$0.00"}</Text>
						<Text style={styles.summaryText}>Processing Fee: $5.00</Text>
						<Text style={styles.summaryText}>Total: ${parseFloat(amount || 0) + processingFee}</Text>
					</View>
					<View style={styles.cardInputContainer}>
						<CardElement style={styles.cardInput} />
					</View>
					<Pressable style={styles.finishButton} onPress={handleSubmit}>
						<Text style={styles.finishButtonText}>Finish</Text>
					</Pressable>
					<Pressable style={styles.closeButton} onPress={onClose}>
						<Text style={styles.buttonText}>Close</Text>
					</Pressable>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: 22,
	},
	modalView: {
		margin: 20,
		backgroundColor: "#fefde2",
		borderRadius: 20,
		padding: 35,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: "90%", // Adjusted width to make it wider
	},
	eCardContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	eCardPreview: {
		width: 300,
		height: 400,
		marginBottom: 15,
	},
	input: {
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 5,
		padding: 10,
		marginBottom: 15,
		width: "100%",
	},
	summary: {
		marginBottom: 15,
		width: "100%", // Ensure summary takes full width
	},
	summaryText: {
		fontSize: 16,
		lineHeight: 24,
	},
	cardInputContainer: {
		width: "100%", // Ensure card input container takes full width
		marginBottom: 15,
	},
	cardInput: {
		width: "100%", // Ensure card input takes full width
		padding: 10,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 5,
	},
	finishButton: {
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		backgroundColor: "#4c956c",
		width: "100%", // Ensure button takes full width
		alignItems: "center",
	},
	finishButtonText: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
	closeButton: {
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		backgroundColor: "red",
		width: "100%", // Ensure button takes full width
		alignItems: "center",
		marginTop: 10,
	},
	buttonText: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
});

export default Checkout;